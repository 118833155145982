import { AxiosError } from "axios";
import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import { SingleResponse } from "../../common/types/SingleResponse";
import * as actionCreators from "../actions/creators/extras";
import * as actionTypes from "../actions/types/extras";
import ExtraService from "../services/ExtraService";
import { GetExtrasResponse } from "../types/Extras";

function* getExtrasWorker({
  applicationId,
  data,
  requestId,
}: actionTypes.GetExtras) {
  try {
    const response: SingleResponse<GetExtrasResponse> = yield call(
      ExtraService.getData,
      applicationId,
      data
    );
    yield put(actionCreators.getExtrasSuccess(response.data, requestId));
  } catch (e) {
    const data = (e as AxiosError)?.response?.data;
    yield put(
      actionCreators.getExtrasFailed(
        {
          code: data?.errorCode,
          message: data?.errorMessage,
        },
        requestId
      )
    );
  }
}

function* watchExtras(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(actionTypes.GET_EXTRAS, getExtrasWorker);
}

export default watchExtras;
