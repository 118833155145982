import { GetExtrasPayload, GetExtrasResponse } from "../../types/Extras";
import * as actionTypes from "../types/extras";

export const getExtras = (
  applicationId: string,
  data: GetExtrasPayload,
  requestId?: string
): actionTypes.GetExtras => ({
  type: actionTypes.GET_EXTRAS,
  applicationId,
  data,
  requestId,
});

export const getExtrasSuccess = (
  response: GetExtrasResponse,
  requestId?: string
): actionTypes.GetExtrasSuccess => ({
  type: actionTypes.GET_EXTRAS_SUCCESS,
  response,
  requestId,
});

export const getExtrasFailed = (
  error: unknown,
  requestId?: string
): actionTypes.GetExtrasFailed => ({
  type: actionTypes.GET_EXTRAS_FAILED,
  error,
  requestId,
});

export const resetExtras = (key: string[]): actionTypes.ResetExtras => ({
  type: actionTypes.RESET_EXTRAS,
  key,
});
