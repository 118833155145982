import { Action } from "redux";
import { GetExtrasPayload, GetExtrasResponse } from "../../types/Extras";

export const GET_EXTRAS = "GET_EXTRAS";
export interface GetExtras extends Action {
  type: typeof GET_EXTRAS;
  applicationId: string;
  data: GetExtrasPayload;
  requestId?: string;
}

export const GET_EXTRAS_SUCCESS = "GET_EXTRAS_SUCCESS";
export interface GetExtrasSuccess extends Action {
  type: typeof GET_EXTRAS_SUCCESS;
  response: GetExtrasResponse;
  requestId?: string;
}

export const GET_EXTRAS_FAILED = "GET_EXTRAS_FAILED";
export interface GetExtrasFailed extends Action {
  type: typeof GET_EXTRAS_FAILED;
  error: unknown;
  requestId?: string;
}

export const RESET_EXTRAS = "RESET_EXTRAS";
export interface ResetExtras extends Action {
  type: typeof RESET_EXTRAS;
  key: string[];
}

export type ExtraActions =
  | GetExtras
  | GetExtrasSuccess
  | GetExtrasFailed
  | ResetExtras;
