import { AxiosError } from "axios";
import { ErrorCode } from "../../types/AuthenticateRequest";
import { deleteTokens } from "../../utils";

export const mismatchedToken = (error: AxiosError): Promise<Error> => {
  const errorCode = error.response?.data?.errorCode;
  if (errorCode === ErrorCode.TOKEN_MISMATCH) {
    deleteTokens();
  }

  return Promise.reject(error);
};
