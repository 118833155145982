import { Action } from "redux";
import {
  InternalNote,
  InternalNotesListParams,
} from "../../types/InternalNotes";

export const GET_APPLICATION_INTERNAL_NOTES_LIST =
  "GET_APPLICATION_INTERNAL_NOTES_LIST";
export interface GetInternalNotesList extends Action {
  type: typeof GET_APPLICATION_INTERNAL_NOTES_LIST;
  applicationId: string;
  params?: InternalNotesListParams;
}

export const GET_APPLICATION_INTERNAL_NOTES_LIST_SUCCESS =
  "GET_APPLICATION_INTERNAL_NOTES_LIST_SUCCESS";
export interface GetInternalNotesListSuccess extends Action {
  type: typeof GET_APPLICATION_INTERNAL_NOTES_LIST_SUCCESS;
  internalNotes: InternalNote[];
  count: number;
}

export const GET_APPLICATION_INTERNAL_NOTES_LIST_ERROR =
  "GET_APPLICATION_INTERNAL_NOTES_LIST_ERROR";
export interface GetInternalNotesListError extends Action {
  type: typeof GET_APPLICATION_INTERNAL_NOTES_LIST_ERROR;
}

export type InternalNotesActions =
  | GetInternalNotesList
  | GetInternalNotesListSuccess
  | GetInternalNotesListError;
