import { Dictionary } from "../../common/types/Dictionary";

export enum DOCUMENT_TYPES {
  CONTRACT_PACK = "CONTRACT_PACK",
  NORTWEST_COLLEGE = "NORTWEST_COLLEGE",
}

export const DOCUMENT_TYPES_OPTIONS: Dictionary = {
  [DOCUMENT_TYPES.CONTRACT_PACK]: "Contract Pack",
  [DOCUMENT_TYPES.NORTWEST_COLLEGE]: "Nortwest College Contract Pack",
};
