import React, { useCallback, useEffect, useState } from "react";
import { CCol, CRow, CInput, CButton } from "@coreui/react";
import { AxiosError } from "axios";
import PopoverInfo from "../../../common/components/PopoverInfo";
import { processErrorMessage } from "../../../error-handler/utils";
import { setNotification } from "../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../notification/dispatchers";
import UserService from "../../../user/services/UserService";
import UserDropdown, { TUser } from "../UserDropdown";

export type FilterData = {
  keyword: string;
  bdm: TUser | null;
};
export type RefClientListFilterProps = {
  onFilterChange?: (values: FilterData) => void;
  onClearFilers?: () => void;
  filterData?: FilterData;
  clientId?: number;
} & React.HTMLProps<HTMLDivElement>;

const defaultFilterData: FilterData = {
  bdm: null,
  keyword: "",
};

const RefClientListFilter: React.FC<RefClientListFilterProps> = (
  props: RefClientListFilterProps
) => {
  const filterData = {
    ...defaultFilterData,
    ...props.filterData,
  };
  const [keyword, setKeyword] = useState("");
  const notifDispatch = useNotificationDispatch();

  useEffect(() => {
    setKeyword(filterData.keyword);
  }, [filterData.keyword]);

  const onFilterChange = (dataProp: Partial<FilterData>) => {
    props.onFilterChange?.({
      ...filterData,
      ...dataProp,
    });
  };

  const onKeywordChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setKeyword(event.target.value);
  };

  const onKeywordSearch: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      onFilterChange({
        keyword,
      });
    }
  };

  const getUserOptions = useCallback(
    async (keyword, cb): Promise<TUser[]> => {
      let options: TUser[] = [];
      try {
        const users = await UserService.getUserList({
          keyword,
          clientId: props.clientId,
        });

        options = users.data.map((user) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          };
        });
      } catch (error) {
        const message = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            body: message,
            className: "qst-notif-danger",
          })
        );
      }

      return options;
    },
    [notifDispatch, props.clientId]
  );

  return (
    <CRow className={props.className}>
      <CCol xs={12} md={6} lg={8} className="d-flex align-items-center">
        <div className="keyword">
          <CInput
            value={keyword}
            onChange={onKeywordChange}
            onKeyPress={onKeywordSearch}
            placeholder="Filter by keyword"
            autoComplete="off"
            type="text"
            data-testid="keyword"
            className="mb-1"
            maxLength={150}
          />
          <button type="submit" data-testid="search-button" />
        </div>
        <PopoverInfo
          positions={["right"]}
          content={
            <>
              <div>
                &quot;Filter by keyword&quot; will work on the following fields:
              </div>
              <ul className="pl-4 mt-2">
                <li>Name</li>
              </ul>
            </>
          }
        />
      </CCol>
      <CCol xs={12} md={6} lg={4}>
        <UserDropdown
          name="bdm"
          id="bdm"
          value={filterData?.bdm}
          loadOptions={getUserOptions}
          onChange={(bdm) => {
            onFilterChange({
              bdm,
            });
          }}
          placeholder="Filter by Broker BDM"
        />
      </CCol>
      <CCol xs={12} className="filters-row-2 my-3">
        <CButton
          className="quest-button primary clear-btn"
          onClick={props.onClearFilers}
          data-testid="clear-filters"
        >
          Clear Filters
        </CButton>
      </CCol>
    </CRow>
  );
};

export default RefClientListFilter;
