import { createSelector } from "@reduxjs/toolkit";
import { ApplicationState } from "../types/ApplicationState";
import { ExtrasState } from "../types/Extras";

export const extrasSelector = (state: ApplicationState): ExtrasState => {
  return state.application.extras;
};

export const extraStatusSelector = createSelector(
  extrasSelector,
  (extraState) => extraState.status
);

export const extrasErrorSelector = createSelector(
  extrasSelector,
  (extraState) => extraState.error
);

export const extrasDataSelector = createSelector(
  extrasSelector,
  (extraState) => {
    return extraState.data;
  }
);
