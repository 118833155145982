import { createSelector } from "@reduxjs/toolkit";
import { ApplicationState } from "../types/ApplicationState";
import { InternalNotesState, NotesList } from "../types/InternalNotes";

export const internalNotesStateSelector = (
  state: ApplicationState
): InternalNotesState => state.application.internalNotes;

export const internalNotesListSelector = createSelector(
  internalNotesStateSelector,
  (internalNotesState): NotesList => internalNotesState.notesList
);
