import { useEffect } from "react";
import * as interceptors from "./errorInterceptors";
import { axiosInstance } from "../../../common/services/APIService";

const useApiAuthInterceptor = (): void => {
  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      interceptors.mismatchedToken
    );
  }, []);
};

export default useApiAuthInterceptor;
