import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as queryString from "query-string";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import DeleteNoteModal from "./DeleteNoteModal";
import IconClose from "../../../../../common/assets/images/icon-sml-close.svg";
import { DATATABLE_MAX_ROWS } from "../../../../../common/constants/datatable";
import {
  getOffset,
  parseSortingObject,
} from "../../../../../common/utils/dataTable";
import { dateFormat } from "../../../../../common/utils/date";
import { getInternalNotesList } from "../../../../actions/creators/internalNotes";
import { useInternalNotesDispatch } from "../../../../dispatchers";
import { getIsApplicationStatusLockedSelector } from "../../../../selectors/applicationForm";
import { internalNotesListSelector } from "../../../../selectors/internalNotes";
import {
  InternalNote,
  InternalNotesListParams,
} from "../../../../types/InternalNotes";
import "./InternalNotesList.scss";

type InternalNotesListProps = {
  applicationId: string;
};

const InternalNotesList: React.FC<InternalNotesListProps> = (
  props: InternalNotesListProps
) => {
  const [noteToDelete, setNoteToDelete] = useState<number | null>(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useInternalNotesDispatch();
  const isApplicationLocked = useSelector(getIsApplicationStatusLockedSelector);
  const queryParams = useMemo(() => {
    return queryString.parse(location.search, {
      parseNumbers: true,
    }) as InternalNotesListParams;
  }, [location.search]);

  const notesList = useSelector(internalNotesListSelector);
  const loadInternalNotes = useCallback(() => {
    const offset = queryParams.page
      ? getOffset(queryParams.page, DATATABLE_MAX_ROWS)
      : undefined;

    dispatch(
      getInternalNotesList(props.applicationId, {
        offset,
      })
    );
  }, [dispatch, queryParams, props.applicationId]);

  const redirect = (queryVar: Record<string, unknown>) => {
    const query = queryString.stringify({
      ...queryString.parse(location.search),
      ...queryVar,
    });
    history.push(`?${query}`);
  };

  const renderNote = (internalNote: InternalNote) => {
    const { id } = internalNote;
    const { firstName, lastName } = internalNote.creator;
    return (
      <div data-testid="note-item">
        <div
          data-testid={`note-item-content-${id}`}
          dangerouslySetInnerHTML={{ __html: internalNote.note }}
        />
        <i className="mt-3 d-block">
          <span data-testid={`note-item-creator-${id}`}>
            {`${firstName} ${lastName}`}
          </span>
          {" - "}
          <span data-testid={`note-item-date-${id}`}>
            {dateFormat(new Date(internalNote.createdAt), "dd/MM/yyyy - kk:mm")}
          </span>
        </i>
      </div>
    );
  };
  const renderDeleteAction = (internalNote: InternalNote) => {
    const { id } = internalNote;
    if (!isApplicationLocked) {
      return (
        <img
          src={IconClose}
          alt="Delete initernal note"
          onClick={() => setNoteToDelete(id)}
          className="delete-btn"
          data-testid={`note-item-delete-${id}`}
        />
      );
    }
  };
  const columns: IDataTableColumn<InternalNote>[] = [
    {
      selector: "note",
      name: "Comments",
      cell: renderNote,
    },
    {
      selector: "actions",
      name: "",
      cell: renderDeleteAction,
      sortable: false,
      width: "70px",
    },
  ];
  const onPageChange = (page: number) => {
    redirect({
      page,
    });
  };
  const onDeleteModalClose = () => {
    setNoteToDelete(null);
  };
  const onAfterDelete = () => {
    loadInternalNotes();
    setNoteToDelete(null);
  };

  useEffect(() => {
    loadInternalNotes();
  }, [location, loadInternalNotes]);

  return (
    <div
      className="quest-datatable intrnal-notes-table"
      data-testid="internal-notes-list"
    >
      <div className="title">Progress History</div>
      <DataTable
        customStyles={{
          rows: {
            style: {
              "align-items": "flex-start",
              padding: "0.5rem 0rem",
            },
          },
        }}
        responsive
        columns={columns}
        data={notesList.data}
        sortServer
        onSort={(column, direction) => {
          const order = parseSortingObject({
            column: column.selector as string,
            direction,
          });
          redirect({
            page: 0,
            order,
          });
        }}
        pagination
        paginationServer
        paginationDefaultPage={queryParams.page ?? 1}
        paginationTotalRows={notesList.count}
        paginationComponentOptions={{ noRowsPerPage: true }}
        progressPending={notesList.isLoading}
        onChangePage={onPageChange}
      />
      <DeleteNoteModal
        applicationId={props.applicationId}
        internalNotedId={noteToDelete}
        onAfterDelete={onAfterDelete}
        onClose={onDeleteModalClose}
      />
    </div>
  );
};

export default InternalNotesList;
