import { AxiosError } from "axios";
import { put, call, ForkEffect, takeLatest } from "redux-saga/effects";
import { ListResponse } from "../../common/types/ListResponse";
import { processErrorMessage } from "../../error-handler/utils";
import { actionCreator as notifAction } from "../../notification";
import {
  getInternalNotesListSuccess,
  getInternalNotesListError,
} from "../actions/creators/internalNotes";
import {
  GetInternalNotesList,
  GET_APPLICATION_INTERNAL_NOTES_LIST,
} from "../actions/types/internalNotes";
import ApplicationService from "../services/ApplicationService";
import { InternalNote } from "../types/InternalNotes";

function* getInternalNotesListWorker({
  applicationId,
  params,
}: GetInternalNotesList) {
  try {
    const internalNotes: ListResponse<InternalNote> = yield call(
      ApplicationService.getInternalNotesList,
      applicationId,
      params
    );
    yield put(getInternalNotesListSuccess(internalNotes));
  } catch (e) {
    const apiError = e as AxiosError;
    const message = processErrorMessage(apiError);

    yield put(
      notifAction.setNotification({
        id: "INTERNAL_NOTES_LIST_ERROR",
        body: message,
        className: "qst-notif-danger",
        toast: true,
      })
    );
    yield put(getInternalNotesListError());
  }
}

function* watchInternalNotes(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    GET_APPLICATION_INTERNAL_NOTES_LIST,
    getInternalNotesListWorker
  );
}

export default watchInternalNotes;
