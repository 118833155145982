import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CButton, CCol, CInput, CLabel, CRow, CSelect } from "@coreui/react";
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import {
  Address,
  AddressAutoComplete,
  ADDRESS_INPUT_TYPES,
} from "../../address-autocomplete";
import IconAdd from "../../common/assets/images/icon-sml-add.svg";
import IconClose from "../../common/assets/images/icon-sml-close.svg";
import ConfirmationModal, {
  MODAL_TYPE,
} from "../../common/components/ConfirmationModal";
import LabelRadioGroups from "../../common/components/LabelRadioGroups";
import { FIELD_TYPE, NumberField } from "../../common/components/NumberField";
import { AUSTRALIAN_STATE_OPTIONS } from "../../common/constants/australianStateOptions";
import { BOOLEAN_OPTIONS } from "../../common/constants/booleanOptions";
import { TITLE_OPTIONS } from "../../common/constants/titles";
import { Dictionary } from "../../common/types/Dictionary";
import { validateDateOfBirthInput } from "../../common/utils/date";
import { parseNumber } from "../../common/utils/number";
import { stringToBoolean } from "../../common/utils/string";
import { getErrorClass } from "../../common/utils/validation";
import { updateGuarantor } from "../actions/creators/applicationForm";
import { GUARANTOR_ASSET_TYPE_LABELS } from "../constants/guarantorAssetTypes";
import { GUARANTOR_LIABILITY_TYPES_LABELS } from "../constants/guarantorLiabilityTypes";
import {
  GUARANTOR_RESIDENTIAL_STATUSES_OPTIONS,
  GUARANTOR_RESIDENTIAL_STATUSES,
} from "../constants/guarantorResidentialStatuses";
import { MARITAL_STATUS_LABELS } from "../constants/maritalStatuses";
import { useApplicationFormDispatch } from "../dispatchers";
import {
  GuarantorAssetForm,
  GuarantorLiabilityForm,
  GuarantorForm,
} from "../types/GuarantorForm";
import { getAddressFromEntity } from "../utils/address";
import { checkHasInvestmentProperty } from "../utils/guarantors";

type AssetFormProps = {
  parentIndex: number;
  index: number;
  data: GuarantorAssetForm;
  isLoading: boolean;
  options: Dictionary;
  setDataFunction: (value: GuarantorAssetForm) => void;
  deleteFunction: () => void;
  readOnly?: boolean;
};

type LiabilityFormProps = {
  parentIndex: number;
  index: number;
  data: GuarantorLiabilityForm;
  isLoading: boolean;
  options: Dictionary;
  setDataFunction: (value: GuarantorLiabilityForm) => void;
  deleteFunction: () => void;
  readOnly?: boolean;
};

const AssetForm: React.FunctionComponent<AssetFormProps> = ({
  parentIndex,
  index,
  data,
  isLoading,
  options,
  setDataFunction,
  deleteFunction,
  readOnly,
}: AssetFormProps) => {
  const { register, errors, clearErrors } = useFormContext();
  const deleteFunctionCallback = useCallback(() => deleteFunction(), [
    deleteFunction,
  ]);

  const updateData = (newData: Partial<GuarantorAssetForm>) => {
    const newValue = {
      ...data,
      ...newData,
    };
    setDataFunction(newValue);
  };

  const handleTypeChange = (type: string) => {
    updateData({ type });
    clearErrors(`guarantors[${parentIndex}].assets[${index}].type`);
  };

  const handleAmountChange = (amount: string) => {
    updateData({ amount });
    clearErrors(`guarantors[${parentIndex}].assets[${index}].amount`);
  };

  const getFieldError = (fieldName: string) => {
    if (
      errors.guarantors &&
      errors.guarantors[parentIndex] &&
      errors.guarantors[parentIndex]["assets"] &&
      errors.guarantors[parentIndex]["assets"][index] &&
      errors.guarantors[parentIndex]["assets"][index][fieldName]
    ) {
      return errors.guarantors[parentIndex]["assets"][index][fieldName].message;
    }

    return "";
  };

  const renderErrorMessage = (fieldName: string) => {
    const error = getFieldError(fieldName);

    if (error)
      return (
        <span
          className="validation-error"
          data-testid={`guarantor-${parentIndex}-assets-${fieldName}-${index}-error`}
        >
          {error}
        </span>
      );

    return <></>;
  };

  return (
    <div
      className={`select-and-input mb-2 ${getErrorClass(
        getFieldError("amount") | getFieldError("type")
      )}`}
    >
      <div className="input-container">
        <CSelect
          innerRef={register()}
          name={`guarantors[${parentIndex}].assets[${index}].type`}
          className="select"
          disabled={isLoading || readOnly}
          value={data.type}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            handleTypeChange(event.target.value)
          }
          data-testid={`guarantor-${parentIndex}-assets-type-${index}`}
        >
          <option value="">Select Asset</option>
          {Object.keys(options).map((option, index) => (
            <option key={index} value={option}>
              {options[option]}
            </option>
          ))}
        </CSelect>
        <NumberField
          readOnly={readOnly}
          getInputRef={register()}
          name={`guarantors[${parentIndex}].assets[${index}].amount`}
          className="input"
          onValueChange={(values) => handleAmountChange(values.value)}
          fieldType={FIELD_TYPE.CURRENCY}
          value={data.amount}
          placeholder="Value"
          data-testid={`guarantor-${parentIndex}-assets-amount-${index}`}
        />
        {renderErrorMessage("type")}
        {renderErrorMessage("amount")}
      </div>
      {index > 0 && (
        <div className="btn-container">
          <CButton
            className="btn-remove"
            disabled={isLoading || readOnly}
            onClick={() => deleteFunctionCallback()}
            data-testid={`delete-guarantor-${parentIndex}-assets-${index}`}
          >
            <img src={IconClose} alt="icon remove asset liability" />
          </CButton>
        </div>
      )}
    </div>
  );
};

const LiabilityForm: React.FunctionComponent<LiabilityFormProps> = ({
  parentIndex,
  index,
  data,
  isLoading,
  options,
  setDataFunction,
  deleteFunction,
  readOnly,
}: LiabilityFormProps) => {
  const { register, errors, clearErrors } = useFormContext();
  const deleteFunctionCallback = useCallback(() => deleteFunction(), [
    deleteFunction,
  ]);

  const updateData = (newData: Partial<GuarantorLiabilityForm>) => {
    const newValue = {
      ...data,
      ...newData,
    };
    setDataFunction(newValue);
  };

  const handleTypeChange = (type: string) => {
    updateData({ type });
    clearErrors(`guarantors[${parentIndex}].liabilities[${index}].type`);
  };

  const handleAmountChange = (amount: string) => {
    updateData({ amount });
    clearErrors(`guarantors[${parentIndex}].liabilities[${index}].amount`);
  };

  const handleMonthlyRepaymentAmountChange = (
    monthlyRepaymentAmount: string
  ) => {
    updateData({ monthlyRepaymentAmount });
    clearErrors(
      `guarantors[${parentIndex}].liabilities[${index}].monthlyRepaymentAmount`
    );
  };

  const handleLenderNameChange = (lenderName: string) => {
    updateData({ lenderName });
    clearErrors(`guarantors[${parentIndex}].liabilities[${index}].lenderName`);
  };

  const getFieldError = (fieldName: string) => {
    if (
      errors.guarantors &&
      errors.guarantors[parentIndex] &&
      errors.guarantors[parentIndex]["liabilities"] &&
      errors.guarantors[parentIndex]["liabilities"][index] &&
      errors.guarantors[parentIndex]["liabilities"][index][fieldName]
    ) {
      return errors.guarantors[parentIndex]["liabilities"][index][fieldName]
        .message;
    }

    return "";
  };

  const renderErrorMessage = (fieldName: string) => {
    const error = getFieldError(fieldName);

    if (error)
      return (
        <span
          className="validation-error"
          data-testid={`guarantor-${parentIndex}-liabilities-${fieldName}-${index}-error`}
        >
          {error}
        </span>
      );

    return <></>;
  };

  return (
    <div
      className={`select-and-input mb-2 ${getErrorClass(
        getFieldError("amount") | getFieldError("type")
      )}`}
    >
      <div className="input-container">
        <CSelect
          innerRef={register()}
          name={`guarantors[${parentIndex}].liabilities[${index}].type`}
          className="select"
          disabled={isLoading || readOnly}
          value={data.type}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            handleTypeChange(event.target.value)
          }
          data-testid={`guarantor-${parentIndex}-liabilities-type-${index}`}
        >
          <option value="">Select Liability</option>
          {Object.keys(options).map((option, index) => (
            <option key={index} value={option}>
              {options[option]}
            </option>
          ))}
        </CSelect>
        <CInput
          readOnly={readOnly}
          innerRef={register()}
          type="text"
          name={`guarantors[${parentIndex}].liabilities[${index}].lenderName`}
          className="input"
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            handleLenderNameChange(event.target.value)
          }
          value={data.lenderName}
          disabled={isLoading}
          placeholder="Lender Name"
          data-testid={`guarantor-${parentIndex}-liabilities-lenderName-${index}`}
        />
        <NumberField
          readOnly={readOnly}
          getInputRef={register()}
          name={`guarantors[${parentIndex}].liabilities[${index}].amount`}
          className="input"
          onValueChange={(values) => handleAmountChange(values.value)}
          fieldType={FIELD_TYPE.CURRENCY}
          value={data.amount}
          placeholder="Balance / Credit Limit"
          data-testid={`guarantor-${parentIndex}-liabilities-amount-${index}`}
        />
        <NumberField
          readOnly={readOnly}
          getInputRef={register()}
          name={`guarantors[${parentIndex}].liabilities[${index}].monthlyRepaymentAmount`}
          className="input"
          onValueChange={(values) =>
            handleMonthlyRepaymentAmountChange(values.value)
          }
          fieldType={FIELD_TYPE.CURRENCY}
          value={data.monthlyRepaymentAmount}
          placeholder="Monthly Repayment"
          data-testid={`guarantor-${parentIndex}-liabilities-monthlyRepaymentAmount-${index}`}
        />
        {renderErrorMessage("type")}
        {renderErrorMessage("lenderName")}
        {renderErrorMessage("amount")}
        {renderErrorMessage("monthlyRepaymentAmount")}
      </div>
      {index > 0 && (
        <div className="btn-container">
          <CButton
            className="btn-remove"
            disabled={isLoading || readOnly}
            onClick={() => deleteFunctionCallback()}
            data-testid={`delete-guarantor-${parentIndex}-liabilities-${index}`}
          >
            <img src={IconClose} alt="icon remove asset liability" />
          </CButton>
        </div>
      )}
    </div>
  );
};

type GuarantorFormComponentProps = {
  applicantAddress: Address;
  guarantor: GuarantorForm;
  indexNumber: number;
  deleteFunction: () => void;
  isLoading: boolean;
  readOnly?: boolean;
};

const GuarantorFormComponent: React.FunctionComponent<GuarantorFormComponentProps> = ({
  applicantAddress,
  guarantor,
  indexNumber,
  deleteFunction,
  isLoading,
  readOnly,
}: GuarantorFormComponentProps) => {
  const [confirmResidentialStatus, setConfirmResidentialStatus] = useState(
    false
  );
  const { register, control, errors, clearErrors } = useFormContext();
  const dispatch = useApplicationFormDispatch();
  const deleteFunctionCallback = useCallback(() => deleteFunction(), [
    deleteFunction,
  ]);

  const [summary, setSummary] = useState<{
    totalAssets: number;
    totalLiabilities: number;
    netPosition: number;
  }>({
    totalAssets: 0,
    totalLiabilities: 0,
    netPosition: 0,
  });

  const shouldDisplayInvestmentPropertyAddress = useMemo(() => {
    return checkHasInvestmentProperty(
      guarantor.assets.map((asset) => asset.type)
    );
  }, [guarantor]);

  useEffect(() => {
    let totalAssets = 0;
    if (guarantor.assets.length > 0) {
      totalAssets = guarantor.assets
        .map((asset) => parseNumber(asset.amount))
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        });
    }

    let totalLiabilities = 0;
    if (guarantor.liabilities.length > 0) {
      totalLiabilities = guarantor.liabilities
        .map((liability) => parseNumber(liability.amount))
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        });
    }

    const netPosition = totalAssets - totalLiabilities;

    setSummary({
      totalAssets,
      totalLiabilities,
      netPosition,
    });
  }, [guarantor]);

  const handleChange = (fieldName: string, value: string) => {
    const singleFieldName = fieldName.replace(
      `guarantors[${indexNumber}].`,
      ""
    );
    dispatch(updateGuarantor({ [singleFieldName]: value }, indexNumber));
    clearErrors(fieldName);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    handleChange(name, value);
  };

  const toggleSameAddressAsApplicant = (isAddressSameAsApplicant: boolean) => {
    let newState: Partial<GuarantorForm> = {
      isAddressSameAsApplicant,
      ...applicantAddress,
    };

    if (!isAddressSameAsApplicant) {
      newState = {
        isAddressSameAsApplicant,
        addressInputType: ADDRESS_INPUT_TYPES.AUTOCOMPLETE,
        addressStreetName: "",
        addressStreetNumber: "",
        addressState: "",
        addressUnitNumber: "",
        addressPostcode: "",
        addressSuburb: "",
      };
    }
    let fields = Object.keys(newState);
    fields = fields.map((field) => `guarantors[${indexNumber}].${field}`);
    clearErrors(fields);
    dispatch(updateGuarantor(newState, indexNumber));
  };

  const handleAssetArrayChange = (index: number, value: GuarantorAssetForm) => {
    const newArray = [...guarantor["assets"]];
    newArray[index] = value;
    dispatch(updateGuarantor({ assets: newArray }, indexNumber));
  };

  const handleLiabilityArrayChange = (
    index: number,
    value: GuarantorLiabilityForm
  ) => {
    const newArray = [...guarantor["liabilities"]];
    newArray[index] = value;
    dispatch(updateGuarantor({ liabilities: newArray }, indexNumber));
  };

  const deleteAsset = (index: number) => {
    const tempAssets = [...guarantor.assets];
    tempAssets.splice(index, 1);
    dispatch(updateGuarantor({ assets: tempAssets }, indexNumber));
  };

  const addAsset = () => {
    const tempAssets = [...guarantor.assets];
    tempAssets.push({ type: "", amount: "" });
    dispatch(updateGuarantor({ assets: tempAssets }, indexNumber));
  };

  const deleteLiabilities = (index: number) => {
    const tempLiabilities = [...guarantor.liabilities];
    tempLiabilities.splice(index, 1);
    dispatch(updateGuarantor({ liabilities: tempLiabilities }, indexNumber));
  };

  const addLiabilities = () => {
    const tempLiabilities = [...guarantor.liabilities];
    tempLiabilities.push({
      type: "",
      lenderName: "",
      amount: "",
      monthlyRepaymentAmount: "",
    });
    dispatch(updateGuarantor({ liabilities: tempLiabilities }, indexNumber));
  };

  const getFieldError = (field: string) => {
    if (
      errors.guarantors &&
      errors.guarantors[indexNumber] &&
      errors.guarantors[indexNumber][field]
    ) {
      return errors.guarantors[indexNumber][field].message;
    }

    return "";
  };

  const renderErrorMessage = (field: string) => {
    const error = getFieldError(field);

    if (error)
      return (
        <span
          className="validation-error"
          data-testid={`${indexNumber}-${field}-error`}
        >
          {error}
        </span>
      );

    return <></>;
  };

  const onResidentStatusDecide = (proceed = false) => {
    if (proceed) {
      handleChange(
        `guarantors[${indexNumber}].residentialStatus`,
        GUARANTOR_RESIDENTIAL_STATUSES.BOARDING
      );
    }
    setConfirmResidentialStatus(false);
  };

  return (
    <div className="guarantor" data-testid={`guarantor-${indexNumber}`}>
      <ConfirmationModal
        toggler={onResidentStatusDecide}
        modalType={MODAL_TYPE.WARNING}
        isShown={confirmResidentialStatus}
        headerText={`Update residential status for Director #${
          indexNumber + 1
        }`}
        bodyText={
          <>
            Boarding is only acceptable
            <br />
            when the spouse owns the property.
          </>
        }
        onCancel={onResidentStatusDecide}
        onConfirm={() => onResidentStatusDecide(true)}
        confirmButtonText="Ok"
        testId={`${indexNumber}-residential-status`}
      />
      <CRow className="mb-3">
        <CCol xs={12} className="guarantor-section-header">
          <h3 className="f-bold section-header">Director #{indexNumber + 1}</h3>{" "}
          {indexNumber > 0 && (
            <CButton
              className="btn-remove-guarantor ml-3"
              onClick={() => deleteFunctionCallback()}
              disabled={isLoading || readOnly}
              data-testid={`delete-guarantor-${indexNumber}`}
            >
              <img
                src={IconClose}
                alt="icon remove director"
                className="mr-2"
              />
              Remove
            </CButton>
          )}
        </CCol>
      </CRow>
      <CRow className="mb-lg-4">
        <CCol
          xl={2}
          xs={12}
          className={`form-group ${getErrorClass(getFieldError("title"))}`}
        >
          <CLabel className="required">Title</CLabel>
          <CSelect
            innerRef={register()}
            name={`guarantors[${indexNumber}].title`}
            onChange={handleInputChange}
            value={guarantor.title}
            disabled={isLoading || readOnly}
            data-testid={`${indexNumber}-title`}
          >
            <option value="">Select</option>
            {Object.keys(TITLE_OPTIONS).map((title, key) => (
              <option key={key} value={title}>
                {TITLE_OPTIONS[title]}
              </option>
            ))}
          </CSelect>
          {renderErrorMessage("title")}
        </CCol>
        <CCol
          xl={2}
          xs={12}
          className={`form-group ${getErrorClass(getFieldError("firstName"))}`}
        >
          <CLabel className="required">First name</CLabel>
          <CInput
            readOnly={readOnly}
            innerRef={register()}
            type="text"
            name={`guarantors[${indexNumber}].firstName`}
            onChange={handleInputChange}
            value={guarantor.firstName}
            disabled={isLoading}
            data-testid={`${indexNumber}-firstName`}
          />
          {renderErrorMessage("firstName")}
        </CCol>
        <CCol xl={2} xs={12} className={`form-group`}>
          <CLabel>Middle name</CLabel>
          <CInput
            readOnly={readOnly}
            innerRef={register()}
            type="text"
            name={`guarantors[${indexNumber}].middleName`}
            onChange={handleInputChange}
            value={guarantor.middleName ? guarantor.middleName : ""}
            disabled={isLoading}
            data-testid={`${indexNumber}-middleName`}
          />
        </CCol>
        <CCol
          xl={3}
          xs={12}
          className={`form-group ${getErrorClass(getFieldError("lastName"))}`}
        >
          <CLabel className="required">Last name</CLabel>
          <CInput
            readOnly={readOnly}
            innerRef={register()}
            type="text"
            name={`guarantors[${indexNumber}].lastName`}
            onChange={handleInputChange}
            value={guarantor.lastName}
            disabled={isLoading}
            data-testid={`${indexNumber}-lastName`}
          />
          {renderErrorMessage("lastName")}
        </CCol>
        <CCol
          xl={2}
          xs={12}
          className={`form-group ${getErrorClass(
            getFieldError("dateOfBirth")
          )}`}
        >
          <CLabel className="required">Date of birth</CLabel>
          <NumberField
            readOnly={readOnly}
            getInputRef={register()}
            placeholder="DD / MM / YYYY"
            format="##/##/####"
            inputMode="numeric"
            name={`guarantors[${indexNumber}].dateOfBirth`}
            onValueChange={(values) =>
              handleChange(
                `guarantors[${indexNumber}].dateOfBirth`,
                values.formattedValue
              )
            }
            isAllowed={(values) =>
              validateDateOfBirthInput(values.formattedValue)
            }
            value={guarantor.dateOfBirth}
            data-testid={`${indexNumber}-dateOfBirth`}
          />
          {renderErrorMessage("dateOfBirth")}
        </CCol>
      </CRow>
      <CRow className="mb-lg-4">
        <CCol
          xl={3}
          xs={12}
          className={`form-group ${getErrorClass(
            getFieldError("driverLicenseNumber")
          )}`}
        >
          <CLabel>Driver licence number</CLabel>
          <CInput
            readOnly={readOnly}
            innerRef={register()}
            type="text"
            name={`guarantors[${indexNumber}].driverLicenseNumber`}
            onChange={handleInputChange}
            maxLength={11}
            value={
              guarantor.driverLicenseNumber ? guarantor.driverLicenseNumber : ""
            }
            disabled={isLoading}
            data-testid={`${indexNumber}-driverLicenseNumber`}
          />
          {renderErrorMessage("driverLicenseNumber")}
        </CCol>
        <CCol
          xl={3}
          xs={12}
          className={`form-group license-card-num-${indexNumber} ${getErrorClass(
            getFieldError("driverLicenseNumber")
          )}`}
        >
          <CLabel>Licence card number</CLabel>
          <CInput
            readOnly={readOnly}
            innerRef={register()}
            type="text"
            name={`guarantors[${indexNumber}].driverLicenseCardNumber`}
            value={guarantor.driverLicenseCardNumber ?? ""}
            onChange={handleInputChange}
            maxLength={11}
            disabled={isLoading}
            data-testid={`${indexNumber}-driverLicenseCardNumber`}
          />
          {renderErrorMessage("driverLicenseCardNumber")}
        </CCol>
        <CCol xl={3} xs={12} className={`form-group`}>
          <CLabel>Licence state</CLabel>
          <CSelect
            innerRef={register()}
            name={`guarantors[${indexNumber}].driverLicenseState`}
            onChange={handleInputChange}
            value={guarantor.driverLicenseState}
            disabled={isLoading || readOnly}
            data-testid={`${indexNumber}-driverLicenseState`}
          >
            <option value="">Select</option>
            {AUSTRALIAN_STATE_OPTIONS.map((state, key) => (
              <option key={key} value={state}>
                {state}
              </option>
            ))}
          </CSelect>
        </CCol>
      </CRow>
      <CRow>
        <CCol
          xl={3}
          xs={12}
          className={`form-group ${getErrorClass(
            getFieldError("maritalStatus")
          )}`}
        >
          <CLabel className="required">Marital status</CLabel>
          <CSelect
            innerRef={register()}
            name={`guarantors[${indexNumber}].maritalStatus`}
            onChange={handleInputChange}
            value={guarantor.maritalStatus}
            disabled={isLoading || readOnly}
            data-testid={`${indexNumber}-maritalStatus`}
          >
            <option value="">Select</option>
            {Object.keys(MARITAL_STATUS_LABELS).map((status, index) => (
              <option key={index} value={status}>
                {MARITAL_STATUS_LABELS[status]}
              </option>
            ))}
          </CSelect>
          {renderErrorMessage("maritalStatus")}
        </CCol>
        <CCol
          xl={3}
          xs={12}
          className={`form-group ${getErrorClass(
            getFieldError("dependentNumber")
          )}`}
        >
          <CLabel className="required">Number of dependents</CLabel>
          <CRow>
            <CCol xl={4} xs={12}>
              <NumberField
                readOnly={readOnly}
                getInputRef={register()}
                name={`guarantors[${indexNumber}].dependentNumber`}
                inputMode="numeric"
                format="##"
                isNumericString
                onValueChange={(value) =>
                  handleChange(
                    `guarantors[${indexNumber}].dependentNumber`,
                    value.value
                  )
                }
                value={guarantor.dependentNumber}
                disabled={isLoading}
                data-testid={`${indexNumber}-dependentNumber`}
              />
            </CCol>
          </CRow>
          {renderErrorMessage("dependentNumber")}
        </CCol>
      </CRow>
      <CRow className="mb-lg-5">
        <CCol
          xl={3}
          xs={12}
          className={`form-group ${getErrorClass(getFieldError("mobile"))}`}
        >
          <CLabel className="required">Mobile</CLabel>
          <NumberField
            readOnly={readOnly}
            getInputRef={register()}
            name={`guarantors[${indexNumber}].mobile`}
            inputMode="tel"
            format="#### ### ###"
            isNumericString
            onValueChange={(value) =>
              handleChange(`guarantors[${indexNumber}].mobile`, value.value)
            }
            value={guarantor.mobile}
            disabled={isLoading}
            data-testid={`${indexNumber}-mobile`}
          />
          {renderErrorMessage("mobile")}
        </CCol>
        <CCol
          xl={6}
          xs={12}
          className={`form-group ${getErrorClass(getFieldError("email"))}`}
        >
          <CLabel className="required">Email</CLabel>
          <CInput
            readOnly={readOnly}
            innerRef={register()}
            type="email"
            name={`guarantors[${indexNumber}].email`}
            onChange={handleInputChange}
            value={guarantor.email}
            disabled={isLoading}
            data-testid={`${indexNumber}-email`}
          />
          {renderErrorMessage("email")}
        </CCol>
      </CRow>
      <hr className="mb-5" />
      <CRow className="mb-lg-3">
        <CCol xs={12}>
          <h3 className="f-bold section-header">Residential address</h3>
        </CCol>
      </CRow>
      <CRow className="mb-lg-4">
        <CCol xl={3} xs={12} className={`form-group`}>
          <CLabel>Same as business address?</CLabel>
          <Controller
            control={control}
            name={`guarantors[${indexNumber}].isAddressSameAsApplicant`}
            defaultValue={guarantor.isAddressSameAsApplicant}
            render={() => (
              <LabelRadioGroups
                fieldName={`guarantors[${indexNumber}].isAddressSameAsApplicant`}
                options={BOOLEAN_OPTIONS}
                checkedValue={guarantor.isAddressSameAsApplicant}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  toggleSameAddressAsApplicant(
                    stringToBoolean(event.target.value)
                  );
                }}
                disabled={isLoading || readOnly}
                testId={`${indexNumber}-isAddressSameAsApplicant`}
              />
            )}
          />
        </CCol>
        <CCol
          xl={6}
          xs={12}
          className={`form-group ${getErrorClass(
            getFieldError("residentialStatus")
          )}`}
        >
          <CLabel className="required">Residential status</CLabel>
          <Controller
            control={control}
            name={`guarantors[${indexNumber}].residentialStatus`}
            defaultValue={guarantor.residentialStatus}
            render={({ onChange, value }) => (
              <LabelRadioGroups
                fieldName={`guarantors[${indexNumber}].residentialStatus`}
                options={GUARANTOR_RESIDENTIAL_STATUSES_OPTIONS}
                checkedValue={value}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (
                    event.target.value ===
                    GUARANTOR_RESIDENTIAL_STATUSES.BOARDING
                  ) {
                    setConfirmResidentialStatus(true);
                    return;
                  }
                  handleChange(
                    `guarantors[${indexNumber}].residentialStatus`,
                    event.target.value
                  );
                  onChange(event.target.value);
                }}
                disabled={isLoading || readOnly}
                testId={`${indexNumber}-residentialStatus`}
              />
            )}
          />

          {renderErrorMessage("residentialStatus")}
        </CCol>
      </CRow>
      <AddressAutoComplete
        readOnly={readOnly}
        uniqueCheckboxId={`${indexNumber}`}
        className="mb-3"
        labelName="Residential address"
        address={getAddressFromEntity(guarantor)}
        arrayIndex={indexNumber}
        arrayPrefix="guarantors"
        onAddressUpdate={(address) =>
          dispatch(
            updateGuarantor(
              {
                ...address,
              },
              indexNumber
            )
          )
        }
        disabled={guarantor.isAddressSameAsApplicant}
        testIdPrefix={`guarantor-${indexNumber}`}
      />
      <hr className="mb-5" />
      <CRow className="mb-3">
        <CCol xs={12}>
          <h3 className="f-bold section-header">Assets and Liabilities</h3>
        </CCol>
      </CRow>
      <CRow className="mb-5 assets-liabilities">
        <CCol xl={6} xs={12} className="assets-section">
          <CRow className="mb-2">
            <CCol xs={12}>
              <div className="heading">
                <CLabel>Assets</CLabel>
                <CLabel>Value</CLabel>
              </div>
              {guarantor.assets.map((asset, index) => (
                <AssetForm
                  readOnly={readOnly}
                  parentIndex={indexNumber}
                  index={index}
                  key={index}
                  data={asset}
                  options={GUARANTOR_ASSET_TYPE_LABELS}
                  isLoading={isLoading}
                  deleteFunction={() => deleteAsset(index)}
                  setDataFunction={(value) =>
                    handleAssetArrayChange(index, value)
                  }
                />
              ))}
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={12} className="d-flex">
              <CButton
                className="btn-add"
                onClick={() => addAsset()}
                disabled={readOnly}
                data-testid={`guarantor-${indexNumber}-add-asset`}
              >
                Add asset <img src={IconAdd} alt="icon-add" />
              </CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow className="mb-5 assets-liabilities">
        <CCol xl={12} xs={12} className="liabilities-section">
          <CRow className="mb-2">
            <CCol xs={12}>
              <div className="heading">
                <CLabel>Liabilities</CLabel>
                <CLabel>Lender Name</CLabel>
                <CLabel>Balance / Credit Limit</CLabel>
                <CLabel>Monthly Repayment</CLabel>
              </div>
              {guarantor.liabilities.map((liability, index) => (
                <LiabilityForm
                  readOnly={readOnly}
                  parentIndex={indexNumber}
                  index={index}
                  key={index}
                  data={liability}
                  options={GUARANTOR_LIABILITY_TYPES_LABELS}
                  isLoading={isLoading}
                  deleteFunction={() => deleteLiabilities(index)}
                  setDataFunction={(value) =>
                    handleLiabilityArrayChange(index, value)
                  }
                />
              ))}
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={12} className="d-flex">
              <CButton
                className="btn-add"
                onClick={() => addLiabilities()}
                disabled={readOnly}
                data-testid={`guarantor-${indexNumber}-add-liability`}
              >
                Add liability <img src={IconAdd} alt="icon-add" />
              </CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      {shouldDisplayInvestmentPropertyAddress && (
        <AddressAutoComplete
          readOnly={readOnly}
          uniqueCheckboxId={`${indexNumber}-investmentAddress`}
          className="mb-3"
          labelName="Investment property address (optional)"
          addressFieldNames={{
            addressInputType: `guarantors[${indexNumber}].investmentPropertyAddressInputType`,
            addressState: `guarantors[${indexNumber}].investmentPropertyAddressState`,
            addressStreetName: `guarantors[${indexNumber}].investmentPropertyAddressStreetName`,
            addressStreetNumber: `guarantors[${indexNumber}].investmentPropertyAddressStreetNumber`,
            addressUnitNumber: `guarantors[${indexNumber}].investmentPropertyAddressUnitNumber`,
            addressSuburb: `guarantors[${indexNumber}].investmentPropertyAddressSuburb`,
            addressPostcode: `guarantors[${indexNumber}].investmentPropertyAddressPostcode`,
            fullAddress: `guarantors[${indexNumber}].investmentPropertyFullAddress`,
          }}
          address={{
            addressInputType: guarantor.investmentPropertyAddressInputType,
            addressState: guarantor.investmentPropertyAddressState,
            addressStreetName: guarantor.investmentPropertyAddressStreetName,
            addressStreetNumber:
              guarantor.investmentPropertyAddressStreetNumber,
            addressUnitNumber: guarantor.investmentPropertyAddressUnitNumber,
            addressSuburb: guarantor.investmentPropertyAddressSuburb,
            addressPostcode: guarantor.investmentPropertyAddressPostcode,
          }}
          onAddressUpdate={(address) =>
            dispatch(
              updateGuarantor(
                {
                  investmentPropertyAddressInputType: address.addressInputType,
                  investmentPropertyAddressUnitNumber:
                    address.addressUnitNumber,
                  investmentPropertyAddressStreetNumber:
                    address.addressStreetNumber,
                  investmentPropertyAddressStreetName:
                    address.addressStreetName,
                  investmentPropertyAddressSuburb: address.addressSuburb,
                  investmentPropertyAddressState: address.addressState,
                  investmentPropertyAddressPostcode: address.addressPostcode,
                },
                indexNumber
              )
            )
          }
          testIdPrefix={`investment-${indexNumber}`}
          isRequired={false}
        />
      )}

      <hr className="mb-5" />
      <CRow className="mb-3">
        <CCol xs={12}>
          <h3 className="f-bold section-header">Financial Summary</h3>
        </CCol>
      </CRow>
      <CRow className="mb-5">
        <CCol xs={12}>
          <div className="financial-summary">
            <div className="summary-row mb-3">
              <div className="label">Total Assets:</div>
              <div className="value">
                <NumberFormat
                  value={summary.totalAssets}
                  prefix="$"
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator=","
                  data-testid={`guarantor-${indexNumber}-summary-total-assets`}
                />
              </div>
            </div>
            <div className="summary-row mb-3">
              <div className="label">Total Liabilities:</div>
              <div className="value">
                <NumberFormat
                  value={summary.totalLiabilities}
                  prefix="$"
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator=","
                  data-testid={`guarantor-${indexNumber}-summary-total-liabilities`}
                />
              </div>
            </div>
            <div className="summary-row">
              <div className="label f-bold">Net Position:</div>
              <div className="value f-bold">
                <NumberFormat
                  value={summary.netPosition}
                  prefix="$"
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator=","
                  data-testid={`guarantor-${indexNumber}-summary-net-position`}
                />
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
  );
};

export default GuarantorFormComponent;
