import * as qs from "query-string";
import { DATATABLE_MAX_ROWS } from "../../common/constants/datatable";
import APIService from "../../common/services/APIService";
import { ListResponse } from "../../common/types/ListResponse";
import { SingleResponse } from "../../common/types/SingleResponse";
import { getOffset } from "../../common/utils/dataTable";
import { ListParams, RefUser, RefUserPayload } from "../types/ref-user";

class UserService {
  public static async listRefUsers(
    params?: ListParams
  ): Promise<ListResponse<RefUser>> {
    const limit = params?.limit ?? DATATABLE_MAX_ROWS;
    const queryParams = qs.stringify({
      limit,
      offset: getOffset(params?.offset ?? 1, limit),
      keywords: params?.keyword,
      clientIds: params?.clientId,
      order: params?.order,
    });

    return await APIService.jsonRequest<ListResponse<RefUser>, true>(
      {
        method: "GET",
        path: "/iam/managed-users?" + queryParams,
      },
      true
    );
  }

  public static async getRefUser(id: number): Promise<SingleResponse<RefUser>> {
    return await APIService.jsonRequest<SingleResponse<RefUser>, true>(
      {
        method: "GET",
        path: "/iam/managed-users/" + id,
      },
      true
    );
  }

  public static async createRefUser(
    data: RefUserPayload
  ): Promise<SingleResponse<RefUser>> {
    return await APIService.jsonRequest<
      SingleResponse<RefUser>,
      RefUserPayload
    >(
      {
        data,
        method: "POST",
        path: "/iam/managed-users",
      },
      true
    );
  }

  public static async updateRefUser(
    id: number,
    data: RefUserPayload
  ): Promise<SingleResponse<RefUser>> {
    return await APIService.jsonRequest<
      SingleResponse<RefUser>,
      RefUserPayload
    >(
      {
        data,
        method: "PATCH",
        path: "/iam/managed-users/" + id,
      },
      true
    );
  }
}

export default UserService;
