import APIService from "../../common/services/APIService";
import { ListResponse } from "../../common/types/ListResponse";
import { SingleResponse } from "../../common/types/SingleResponse";
import { ApplicationConfig } from "../types/ApplicationConfig";
import {
  ApplicationDocument,
  PrivacyFormLog,
} from "../types/ApplicationDocument";
import { DocumentGenerateRequest } from "../types/DocumentGenerateRequest";

class ApplicationDocumentService {
  public static async generateDocuments(
    applicationId: string,
    types: string[],
    isEsign: boolean
  ): Promise<ListResponse<ApplicationDocument> | undefined> {
    const result = await APIService.jsonRequest<
      ListResponse<ApplicationDocument>,
      DocumentGenerateRequest
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/documents/generate`,
        data: {
          types,
          isEsign,
        },
      },
      true
    );
    return result;
  }

  public static async getDocumentGenerateOptions(
    applicationId: string
  ): Promise<SingleResponse<ApplicationConfig["contractDocument"]>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicationConfig["contractDocument"]>,
      DocumentGenerateRequest
    >(
      {
        method: "GET",
        path: `/application/applications/${applicationId}/documents/contract-options`,
      },
      true
    );
    return result;
  }

  public static async downloadPrivacyForm(
    applicationId: string
  ): Promise<SingleResponse<string>> {
    const result = await APIService.jsonRequest<SingleResponse<string>, null>(
      {
        method: "GET",
        path: `/application/applications/${applicationId}/privacy-consent/download`,
      },
      true
    );
    return result;
  }

  public static async sendPrivacyForm(
    applicationId: string
  ): Promise<SingleResponse<ApplicationDocument>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicationDocument>,
      null
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/privacy-consent`,
      },
      true
    );
    return result;
  }

  public static async sendPrivacyFormReminder(
    applicationId: string
  ): Promise<SingleResponse<string>> {
    const result = await APIService.jsonRequest<
      SingleResponse<string>,
      unknown
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/privacy-consent/remind`,
        data: {},
      },
      true
    );
    return result;
  }

  public static async getPrivacyFormLogs(
    applicationId: string
  ): Promise<ListResponse<PrivacyFormLog>> {
    const result = await APIService.jsonRequest<
      ListResponse<PrivacyFormLog>,
      null
    >(
      {
        method: "GET",
        path: `/application/applications/${applicationId}/privacy-consent/log`,
      },
      true
    );
    return result;
  }
}

export default ApplicationDocumentService;
