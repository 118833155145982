import { createSelector } from "@reduxjs/toolkit";
import { ApplicationListState } from "../types/ApplicationListState";
import { ApplicationState } from "../types/ApplicationState";

export const applicationListStateSelector = (
  state: ApplicationState
): ApplicationListState => state.application.applicationList;

export const getApplicationListSelector = createSelector(
  applicationListStateSelector,
  (applicationList) => applicationList.applications
);

export const getApplicationCountSelector = createSelector(
  applicationListStateSelector,
  (applicationList) => applicationList.applicationCount
);

export const getLoadingSelector = createSelector(
  applicationListStateSelector,
  (applicationList) => applicationList.isLoading
);
