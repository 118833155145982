import APIService from "../../common/services/APIService";
import { SingleResponse } from "../../common/types/SingleResponse";
import {
  UpdateExtrasPayload,
  GetExtrasPayload,
  GetExtrasResponse,
} from "../types/Extras";

class ExtraService {
  public static async setData(
    applicationtId: string,
    data: UpdateExtrasPayload
  ): Promise<SingleResponse<GetExtrasPayload>> {
    const result = await APIService.jsonRequest<
      SingleResponse<GetExtrasPayload>,
      UpdateExtrasPayload
    >(
      {
        method: "PUT",
        path: `/application/applications/${applicationtId}/extra`,
        data,
      },
      true
    );
    return result;
  }

  public static async getData(
    applicationtId: string,
    data: GetExtrasPayload
  ): Promise<SingleResponse<GetExtrasResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<GetExtrasResponse>,
      GetExtrasPayload
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationtId}/extra`,
        data,
      },
      true
    );
    return result;
  }
}

export default ExtraService;
