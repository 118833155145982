import { LOADING_STATUS } from "../../common/constants/loadingStatuses";
import { ExtrasState } from "../types/Extras";

export const EXTRAS_DEFAULT_VALUE: ExtrasState = {
  data: {},
  status: LOADING_STATUS.IDLE,
  requestStatus: {},
};

export const APPLICANT_EQUIFAX_ENQUIRY_KEY =
  "EQUIFAX.COMPANY_ENQUIRY.APPLICANT";
export const APPLICANT_ENQUIRY_DOCUMENT =
  "EQUIFAX.COMPANY_ENQUIRY_DOCUMENT.APPLICANT";
export const GUARANTOR1_EQUIFAX_APPLY = "EQUIFAX.COMPANY_APPLY.GUARANTOR_1";
export const GUARANTOR1_DOCUMENT = "EQUIFAX.COMPANY_APPLY_DOCUMENT.GUARANTOR_1";
export const GUARANTOR2_EQUIFAX_APPLY = "EQUIFAX.COMPANY_APPLY.GUARANTOR_2";
export const GUARANTOR2_DOCUMENT = "EQUIFAX.COMPANY_APPLY_DOCUMENT.GUARANTOR_2";
export const QUEST_FINANCE_PRIVACY_FORM = "QUEST_FINANCE.PRIVACY_FORM";

export const FIRST_TIME_REQUEST_MESSAGE =
  "Are you sure you want to procceed with this action?";
export const SUBSEQUENT_REQUEST_MESSAGE =
  "A previous request was already fulfilled. Are you sure you want to procceed?";

export enum REQUEST_KEY {
  APPROVAL_EXPIRY = "APPROVAL_EXPiRY",
}
