import {
  ApplicationListActions,
  GET_APPLICATION_LIST,
  GET_APPLICATION_LIST_ERROR,
  GET_APPLICATION_LIST_SUCCESS,
} from "../actions/types/applicationList";
import { ApplicationListState } from "../types/ApplicationListState";

export const applicationListState: ApplicationListState = {
  applications: [],
  applicationCount: 0,
  isLoading: false,
};

export const reducer = (
  state: ApplicationListState = applicationListState,
  action: ApplicationListActions
): ApplicationListState => {
  switch (action.type) {
    case GET_APPLICATION_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_APPLICATION_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        applications: action.applications,
        applicationCount: action.applicationCount,
      };
    }
    case GET_APPLICATION_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
