import { useEffect, useRef } from "react";

const useDidMount = (cb: () => void): void => {
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      cb();
    }
    didMount.current = true;
  }, [cb]);
};

export default useDidMount;
