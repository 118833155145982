import "./ModalExpired.scss";
import React, { useEffect, useState } from "react";
import { CModal, CModalBody } from "@coreui/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { checkAuthState } from "../actions/creators/authentication";
import { useAuthenticationDispatch } from "../dispatchers";
import { getLastAuthPrompt } from "../selectors/authentication";

const ModalExpired: React.FunctionComponent = () => {
  const [shown, setShown] = useState(false);
  const lastAuthPrompt = useSelector(getLastAuthPrompt);
  const authDispatch = useAuthenticationDispatch();
  const history = useHistory();

  useEffect(() => {
    if (lastAuthPrompt) {
      setShown(true);
    }
  }, [lastAuthPrompt]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      authDispatch(checkAuthState());
    });

    return unlisten;
  }, [history, authDispatch]);

  return (
    <CModal
      className="modal-expired"
      data-testid="session-expired-modal"
      show={shown}
      onClosed={() => setShown(false)}
      closeOnBackdrop={false}
    >
      <CModalBody>
        <h4 className="f-quest-navy f-bold">Attention</h4>
        <p className="f-quest-navy">
          Your session has been expired. Click the button below to renew your
          session.
        </p>
        <Link
          className="quest-button purple f-normal f-14"
          data-testid="login-button"
          to="/auth/login"
        >
          Login
        </Link>
      </CModalBody>
    </CModal>
  );
};

export default ModalExpired;
