import { useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";
import _pick from "lodash/pick";
import qs from "query-string";
import { useHistory } from "react-router";
import { processErrorMessage } from "../../error-handler/utils";
import { setNotification } from "../../notification/actions/creators";
import { useNotificationDispatch } from "../../notification/dispatchers";
import UserService from "../services/UserService";
import { ListParams, ListQueryParams, RefUser } from "../types/ref-user";

type RefUserList = {
  data: RefUser[];
  count: number;
  loading: boolean;
};

type HookArgs = {
  queryParams: ListQueryParams;
  enabled?: boolean;
};

type HookReturnType = {
  refUsers: RefUserList;
  setQueryParams: (data: ListQueryParams) => void;
};

const useRefUserList = (args: HookArgs): HookReturnType => {
  const { enabled = true } = args;
  const notifDispatch = useNotificationDispatch();
  const history = useHistory();
  const [refUsers, setRefUsers] = useState<RefUserList>({
    data: [],
    count: 0,
    loading: false,
  });

  const setQueryParams = (overrideValues: ListQueryParams) => {
    const newQueryParams = qs.stringify(
      {
        ...args.queryParams,
        ...overrideValues,
      },
      {
        arrayFormat: "comma",
      }
    );
    history.push("?" + newQueryParams);
  };

  const getRefUsers = useCallback(
    async (queryParams: ListQueryParams) => {
      setRefUsers({
        data: [],
        count: 0,
        loading: true,
      });

      let refUsers: {
        data: RefUser[];
        count: number;
      } = {
        data: [],
        count: 0,
      };

      try {
        const params: ListParams = {
          ..._pick(queryParams, ["keyword", "limit", "order"]),
          offset: queryParams.page,
          clientId: queryParams?.client?.[0],
        };

        const resp = await UserService.listRefUsers(params);
        refUsers = {
          data: resp.data,
          count: resp.count ?? 0,
        };
      } catch (error) {
        const message = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            body: message,
            className: "qst-notif-danger",
          })
        );
      } finally {
        setRefUsers({
          ...refUsers,
          loading: false,
        });
      }
    },
    [notifDispatch]
  );

  useEffect(() => {
    if (enabled) {
      getRefUsers(args.queryParams);
    }
  }, [getRefUsers, args.queryParams, enabled]);

  return { refUsers, setQueryParams };
};

export default useRefUserList;
