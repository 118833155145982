import React, { useEffect } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { CCol, CContainer, CRow } from "@coreui/react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import CreditReport from "./CreditReport/CreditReport";
import InternalNotesEditor from "./InternalNotes/InternalNotesEditor";
import InternalNotesList from "./InternalNotes/InternalNotesList";
import StatusAndLender from "./StatusAndLender";
import { getUserDataSelector } from "../../../../auth";
import { UserData } from "../../../../auth/types/UserData";
import { isAllowedAccess } from "../../../../auth/utils";
import { permissions } from "../../../../iam";
import { APPLICATION_STEPS } from "../../../constants/applicationSteps";
import {
  getApplicationSelector,
  getStepsSelector,
} from "../../../selectors/applicationForm";
import "./Administration.scss";

const Administration: React.FC = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const application = useSelector(getApplicationSelector);
  const userData = useSelector(getUserDataSelector) as UserData;
  const steps = useSelector(getStepsSelector);
  const history = useHistory();
  const afterNotesCreateHandler = () => {
    history.push("?");
  };

  useEffect(() => {
    if (
      !isAllowedAccess(userData, [
        permissions.PERMISSION_IAM["APPLICATION.MANAGE"],
      ]) ||
      (application.id && !steps[APPLICATION_STEPS.guarantors].isStepDone)
    ) {
      history.push("/");
    }
  }, [application, steps, userData, history]);

  return (
    <div className="application-admin">
      <div className="section">
        <h2 className="header mx-3">Status and Lender</h2>
        <StatusAndLender />
      </div>
      <div className="section customer-info">
        <CContainer>
          <CRow>
            <CCol xs={12}>
              <h2 className="header">Customer Information</h2>
              <div className="warn">
                <WarningOutlined /> You must have signed Privacy Consent forms
                before running credit searches
              </div>
              <CreditReport />
            </CCol>
          </CRow>
        </CContainer>
      </div>
      <div className="section">
        <CContainer>
          <CRow>
            <CCol xs={12}>
              <h2 className="header">Progress notes</h2>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={12} md={6}>
              <InternalNotesEditor
                applicationId={applicationId}
                onSucessfulCreate={afterNotesCreateHandler}
              />
            </CCol>
            <CCol xs={12} md={6}>
              <InternalNotesList applicationId={applicationId} />
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  );
};

export default Administration;
