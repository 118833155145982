export interface RequiredDocuments {
  supportingInfo: string;
  bankStatementRequired: boolean;
  bankStatementLink: Array<{
    default?: boolean;
    label: string;
    url: string;
  }> | null;
}

export const requiredDocumentsDefaultValue: RequiredDocuments = {
  supportingInfo: "",
  bankStatementRequired: false,
  bankStatementLink: null,
};
