import { LOADING_STATUS } from "../../common/constants/loadingStatuses";
import * as actionTypes from "../actions/types/extras";
import { EXTRAS_DEFAULT_VALUE } from "../constants/extra";
import { ExtrasState, GetExtrasResponse } from "../types/Extras";

const extraState: ExtrasState = EXTRAS_DEFAULT_VALUE;

export const reducer = (
  state: ExtrasState = extraState,
  action: actionTypes.ExtraActions
): ExtrasState => {
  switch (action.type) {
    case actionTypes.GET_EXTRAS: {
      const requestStatus = { ...state.requestStatus };

      if (action.requestId) {
        requestStatus[action.requestId] = {
          loading: true,
        };
      }

      return {
        ...state,
        requestStatus,
        status: LOADING_STATUS.LOADING,
      };
    }
    case actionTypes.GET_EXTRAS_SUCCESS: {
      const requestStatus = { ...state.requestStatus };
      if (action.requestId) {
        delete requestStatus[action.requestId];
      }

      return {
        ...state,
        status: LOADING_STATUS.SUCCESS,
        data: {
          ...state.data,
          ...action.response,
        },
      };
    }
    case actionTypes.GET_EXTRAS_FAILED: {
      const requestStatus = { ...state.requestStatus };
      let error = undefined;

      if (action.requestId) {
        requestStatus[action.requestId] = {
          loading: false,
          error: action.error,
        };
      } else {
        error = action.error;
      }

      return {
        ...state,
        error,
        status: LOADING_STATUS.FAILED,
        requestStatus,
      };
    }
    case actionTypes.RESET_EXTRAS: {
      const clearedProps: GetExtrasResponse = {};
      action.key.forEach((key) => {
        clearedProps[key] = {};
      });

      return {
        ...state,
        data: {
          ...state.data,
          ...clearedProps,
        },
      };
    }
    default: {
      return state;
    }
  }
};
