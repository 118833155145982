import joi from "joi";
import { Status } from "../types/ref-user";

const stringValidation = (label: string) =>
  joi.string().label(label).max(100).required().messages({
    "string.empty": "{{#label}} is required",
    "any.required": "{{#label}} is required",
  });

export const refUsersonSchema = joi.object({
  firstName: stringValidation("First Name"),
  lastName: stringValidation("Last Name"),
  email: joi
    .string()
    .email({ tlds: { allow: false } })
    .label("Email")
    .max(100)
    .required()
    .messages({
      "string.email": "{{#label}} is invalid",
      "string.empty": "{{#label}} is required",
      "any.required": "{{#label}} is required",
    }),
  mobile: stringValidation("Mobile"),
  status: joi
    .string()
    .label("Status")
    .valid(...Object.values(Status))
    .required()
    .messages({
      "any.empty": "{{#label}} is required",
      "any.required": "{{#label}} is required",
    }),
  client: joi.object().label("Referrer Company").required().messages({
    "object.base": "{{#label}} is required",
    "any.required": "{{#label}} is required",
  }),
});
