import APIService from "../../common/services/APIService";
import { SingleResponse } from "../../common/types/SingleResponse";
import { FeatureFlags } from "../types/Feature";

class FeatureService {
  public static async getFeaturesByKeys(
    features: string[]
  ): Promise<SingleResponse<FeatureFlags>> {
    const result = await APIService.jsonRequest<
      SingleResponse<FeatureFlags>,
      unknown
    >(
      {
        method: "GET",
        path: `/app/feature?name=${features.join(",")}`,
      },
      true
    );
    return result;
  }
}

export default FeatureService;
