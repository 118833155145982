import { RefClientFormData, Status } from "../types/ref-client";

export const defaultRefClient: RefClientFormData = {
  name: "",
  status: Status.ACTIVE,
};

export const STATUS: Record<Status, string> = {
  [Status.ACTIVE]: "Active",
  [Status.SUSPENDED]: "Suspended",
  [Status.CLOSED]: "Closed",
};
