import joi from "joi";

export const refClientSchema = joi.object({
  name: joi.string().required().messages({
    "string.empty": "Name is required",
    "any.required": "Name is required",
  }),
  status: joi.string().required().messages({
    "string.empty": "Status is required",
    "any.required": "Status is required",
  }),
});
