import React, { useState } from "react";
import { CModal, CHeader, CModalBody, CModalFooter } from "@coreui/react";
import { AxiosError } from "axios";
import {
  BUTTON_COLORS,
  QuestButton,
} from "../../../../../common/components/QuestButton";
import { processErrorMessage } from "../../../../../error-handler/utils";
import { setNotification } from "../../../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../../../notification/dispatchers";
import ApplicationService from "../../../../services/ApplicationService";

type DeleteNoteModalProps = {
  applicationId: string;
  internalNotedId: number | null;
  onClose: () => void;
  onAfterDelete?: () => void;
};

const DeleteNoteModal: React.FunctionComponent<DeleteNoteModalProps> = ({
  applicationId,
  internalNotedId,
  onClose,
  onAfterDelete,
}: DeleteNoteModalProps) => {
  const notifDispatch = useNotificationDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const onWithdrawHandler = async () => {
    setIsLoading(true);
    try {
      await ApplicationService.deleteInternalNote(
        applicationId,
        internalNotedId as number
      );
      onAfterDelete?.();
    } catch (error) {
      const message = processErrorMessage(error as AxiosError);
      notifDispatch(
        setNotification({
          body: message,
          className: "qst-notif-danger",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CModal
      className="delete-internal-note-modal"
      show={Boolean(internalNotedId)}
      onClose={onClose}
      closeOnBackdrop={false}
      centered={true}
    >
      <CHeader>
        <h3 data-testid="withdraw-modal-header">Delete Internal Note</h3>
        <button
          type="button"
          className="btn-close"
          onClick={onClose}
          data-testid="withdraw-close-btn"
        >
          x
        </button>
      </CHeader>
      <CModalBody className="px-5">
        <div className="text-center">
          Are you sure you want to proceed with this action?
        </div>
      </CModalBody>
      <CModalFooter>
        <QuestButton
          color={BUTTON_COLORS.SECONDARY}
          onClick={onClose}
          data-testid="cancel-delete-btn"
        >
          Cancel
        </QuestButton>
        <QuestButton
          color={BUTTON_COLORS.COMMIT}
          onClick={onWithdrawHandler}
          data-testid="confirm-delete-btn"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Proceed"}
        </QuestButton>
      </CModalFooter>
    </CModal>
  );
};

export default DeleteNoteModal;
