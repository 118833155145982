import { RefClient } from "./ref-client";

export enum Status {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  CLOSED = "CLOSED",
}

export type ListQueryParams = Partial<{
  limit: number;
  order: string;
  page: number;
  keyword: string;
  client: [number, string];
}>;

export type ListParams = {
  limit?: number;
  offset?: number;
  order?: string;
  keyword?: string;
  clientId?: number;
};

export type RefUser = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  clientId: number;
  client: RefClient;
  status: Status;
  lastLoginAt: string;
};

export type RefUserPayload = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  status: Status;
  clientId: number;
};

export type RefClientField = {
  value: number;
  label: string;
};

export interface RefUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  status: Status;
  client: RefClientField | null;
}

export interface ValidRefUserFormData extends Omit<RefUserFormData, "client"> {
  client: RefClientField;
}
