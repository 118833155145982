import "./index.scss";
import React, { useCallback, useEffect, useState } from "react";
import { AuditOutlined, RocketOutlined } from "@ant-design/icons";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react";
import { AxiosError } from "axios";
import cs from "classnames";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
import { ArrowContainer, Popover } from "react-tiny-popover";
import { getUserDataSelector } from "../../../auth";
import { UserData } from "../../../auth/types/UserData";
import { isAllowedAccess } from "../../../auth/utils";
import ConfirmationModal, {
  MODAL_TYPE,
} from "../../../common/components/ConfirmationModal";
import Loading from "../../../common/components/Loading";
import { processErrorMessage } from "../../../error-handler/utils";
import { permissions } from "../../../iam";
import ClientService from "../../../iam/services/ClientService";
import {
  actionCreator as notifAction,
  dispatch,
  selector,
} from "../../../notification";
import { setNotification } from "../../../notification/actions/creators";
import {
  clearApplicationState,
  clearClonedApplicationId,
  clearRedirectPath,
  getApplicationDetails,
  resetApplicationDetails,
  revertChanges,
  saveForm,
  setActiveStep,
  setIsApplicationAmended,
} from "../../actions/creators/applicationForm";
import IconStep from "../../components/IconStep";
import { APPLICATION_PUBLIC_STATUS_LABELS } from "../../constants/applicationStatuses";
import {
  APPLICATION_STEPS,
  APPLICATION_STEPS_LABELS,
} from "../../constants/applicationSteps";
import { NOTIFICATION_IDS } from "../../constants/notificationIds";
import { REDIRECT_TYPES } from "../../constants/redirectTypes";
import { useApplicationFormDispatch } from "../../dispatchers";
import { default as tabRoutes } from "../../routes/application-details";
import {
  getApplicationSelector,
  getCurrentStepSelector,
  getDetailsLoadingSelector,
  getIsApplicationAmendedSelector,
  getIsFormLoadingSelector,
  getNextPathSelector,
  getRedirectSelector,
  getStepsSelector,
} from "../../selectors/applicationForm";
import { ApplicationConfig } from "../../types/ApplicationConfig";
import { ApplicationSteps } from "../../types/ApplicationStep";

const { useNotificationDispatch } = dispatch;

type AssessmentAdminLinkProps = {
  assessmentId?: string;
  assessmentUrl?: string;
};

const AssessmentAdminLink: React.FC<AssessmentAdminLinkProps> = (
  props: AssessmentAdminLinkProps
) => {
  const { assessmentId = "", assessmentUrl = "" } = props;
  const [popOverOpen, setPopoverOpen] = useState(false);

  return (
    <>
      {Boolean(assessmentUrl && assessmentId) && (
        <Popover
          containerParent={
            document.querySelector(".application-info") as HTMLElement
          }
          isOpen={popOverOpen}
          positions={["top"]}
          padding={0}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor="#000"
              arrowSize={10}
            >
              <div className="popover-content">View Assessment</div>
            </ArrowContainer>
          )}
        >
          <Link
            to={assessmentUrl.replace(
              "{{ASSESSMENT_ID}}",
              assessmentId as string
            )}
            className="assessment-admin-link"
            data-testid="assessment-admin-link"
            onMouseEnter={() => setPopoverOpen(true)}
            onMouseLeave={() => setPopoverOpen(false)}
          >
            <RocketOutlined className="icon" />
          </Link>
        </Popover>
      )}
    </>
  );
};

type ApplicationAdminLinkProps = {
  applicationId?: string;
};

const ApplicationAdminLink: React.FC<ApplicationAdminLinkProps> = (
  props: ApplicationAdminLinkProps
) => {
  const { applicationId } = props;
  const [popOverOpen, setPopoverOpen] = useState(false);

  return (
    <>
      <Popover
        containerParent={
          document.querySelector(".application-info") as HTMLElement
        }
        isOpen={popOverOpen}
        positions={["top"]}
        padding={0}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="#000"
            arrowSize={10}
          >
            <div className="popover-content">Administration</div>
          </ArrowContainer>
        )}
      >
        <Link
          to={`/application/applications/${applicationId}/admin`}
          className="application-admin-link"
          data-testid="application-admin-link"
          onMouseEnter={() => setPopoverOpen(true)}
          onMouseLeave={() => setPopoverOpen(false)}
        >
          <AuditOutlined className="icon" />
        </Link>
      </Popover>
    </>
  );
};

type ApplicationDetailsProps = {
  applicationId?: string;
  tabName?: APPLICATION_STEPS;
  pageAfterSave?: string;
  assessmentUrl?: string;
  enableApplicationAdmin?: boolean;
};

/**
 * @param {string} applicationId - the id of an application to be retrieved
 * @param {string} tabName - current active tab, can be retrieved from the match.params object
 * @param {string} pageAfterSave - path to which the page will be redirected after hitting the "Save and exit" button
 * @param {string} assessmentUrl - needs to include {{ASSESSMENT_ID}} template string to be replaced by the actual `assessmentId`
 * @param {string} enableApplicationAdmin - show application admin link if user has `Application manage` permission
 */
const ApplicationDetails: React.FunctionComponent<ApplicationDetailsProps> = ({
  applicationId,
  tabName,
  assessmentUrl,
  enableApplicationAdmin = true,
  pageAfterSave = "/application/applications",
}: ApplicationDetailsProps) => {
  const history = useHistory();
  const location = useLocation();
  const application = useSelector(getApplicationSelector);
  const userData = useSelector(getUserDataSelector) as UserData;
  const isApplicationAmended = useSelector(getIsApplicationAmendedSelector);
  const currentStep = useSelector(getCurrentStepSelector);
  const getDetailsLoading = useSelector(getDetailsLoadingSelector);
  const steps = useSelector(getStepsSelector);
  const redirect = useSelector(getRedirectSelector);
  const isFormLoading = useSelector(getIsFormLoadingSelector);
  const nextPath = useSelector(getNextPathSelector);
  const errorMessage = useSelector(
    selector.notificationItemSelector(
      NOTIFICATION_IDS.NOT_FOUND_ERRORS + applicationId
    )
  );
  const [applicationConfig, setApplicationConfig] = useState<{
    loading: boolean;
    data?: ApplicationConfig;
  }>({
    loading: false,
  });
  let assessmentLink = null;
  let applicationAdminLink = null;
  const urlSegments = location.pathname.split("/");
  const lastUrlSegment = urlSegments[urlSegments.length - 1];
  const isAdminPage = lastUrlSegment === "admin";

  const dispatch = useApplicationFormDispatch();
  const notifDispatch = useNotificationDispatch();

  const getApplicationConfig = useCallback(
    async (clientId: number) => {
      setApplicationConfig({ loading: true });

      try {
        const clientConfig = await ClientService.getConfig(clientId);
        setApplicationConfig({
          loading: false,
          data: clientConfig.application,
        });
      } catch (error) {
        const message = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            body: message,
            className: "qst-notif-danger",
          })
        );
        setApplicationConfig({ loading: false });
      }
    },
    [notifDispatch, setApplicationConfig]
  );

  useEffect(() => {
    if (errorMessage) {
      history.replace(pageAfterSave);
    }
  }, [errorMessage, history, pageAfterSave, notifDispatch]);

  useEffect(() => {
    notifDispatch(notifAction.clearNotification());
    return () => {
      dispatch(clearApplicationState());
    };
  }, [dispatch, notifDispatch]);

  useEffect(() => {
    dispatch(clearClonedApplicationId());
    if (applicationId === "new") {
      dispatch(resetApplicationDetails(userData?.clientConfig.application));
    } else {
      dispatch(getApplicationDetails(applicationId));
    }
  }, [dispatch, applicationId, userData]);

  useEffect(() => {
    if (applicationId === "new") {
      setApplicationConfig({
        loading: false,
        data: userData?.clientConfig.application,
      });
    }
  }, [applicationId, userData]);

  useEffect(() => {
    if (application.clientId) {
      getApplicationConfig(application.clientId);
    }
  }, [application.clientId, getApplicationConfig]);

  useEffect(() => {
    if (typeof tabName !== "undefined") {
      if (typeof APPLICATION_STEPS_LABELS[tabName] === "undefined") {
        notifDispatch(
          notifAction.setNotification({
            body: "The resource you're trying to access is not available",
          })
        );
        history.replace(pageAfterSave);
      }

      dispatch(setActiveStep(tabName));
    }
  }, [tabName, pageAfterSave, history, dispatch, notifDispatch]);

  useEffect(() => {
    if (redirect.redirectPath) {
      dispatch(clearRedirectPath());
      if (redirect.redirectType === REDIRECT_TYPES.PUSH) {
        history.push(redirect.redirectPath);
      } else {
        history.replace(redirect.redirectPath);
      }
    }
  }, [redirect, history, dispatch]);

  if (application.assessmentId) {
    assessmentLink = (
      <AssessmentAdminLink
        assessmentId={application.assessmentId}
        assessmentUrl={assessmentUrl}
      />
    );
  }

  if (
    enableApplicationAdmin &&
    steps[APPLICATION_STEPS.guarantors].isStepDone &&
    isAllowedAccess(userData, [
      permissions.PERMISSION_IAM["APPLICATION.MANAGE"],
    ])
  ) {
    applicationAdminLink = (
      <ApplicationAdminLink applicationId={applicationId} />
    );
  }

  return (
    <>
      <CRow className="quest-page-header-section">
        <CCol xs={12} className="align-items-center d-flex">
          {application.applicant && application.applicant.entityName ? (
            <h2 className="quest-header-text">
              {APPLICATION_PUBLIC_STATUS_LABELS[application.publicStatus]}
              {" - "}
              {application.applicant.entityName}
            </h2>
          ) : (
            <h2 className="quest-header-text">New Application</h2>
          )}
        </CCol>
      </CRow>
      <CRow className="quest-details-content-section application-details-content">
        <CCol xl={12}>
          <CCard className="quest-card">
            <CCardHeader className={cs({ admin: isAdminPage })}>
              <ul className="application-steps">
                {currentStep &&
                  Object.keys(steps).map((key) => {
                    const step = steps[key as keyof ApplicationSteps];
                    return (
                      Boolean(step.visibleStep) && (
                        <IconStep
                          key={key}
                          isActive={currentStep.stepSlug === step.stepSlug}
                          isDone={step.isStepDone}
                          isClickable={
                            applicationId !== "new" && step.isClickable
                          }
                          onClick={() =>
                            dispatch(setActiveStep(step.stepSlug, tabName))
                          }
                          iconPath={step.stepIcon}
                          stepName={step.stepName}
                        />
                      )
                    );
                  })}
              </ul>
              {applicationId !== "new" && (
                <div className="application-info">
                  {applicationAdminLink}
                  {assessmentLink}
                  <span className="contract-no-text">
                    Loan # {application.applicationNumber}
                  </span>
                  {application.user && (
                    <span className="application-number">
                      {application.user.client} ({application.user.firstName}{" "}
                      {application.user.lastName})
                    </span>
                  )}
                </div>
              )}
            </CCardHeader>
            {currentStep && (
              <CCol xs={12} className="step-header">
                <img src={currentStep.headerIcon} alt="step-icon" />
                <h2 className="step-name" data-testid="step-name">
                  {currentStep.stepName}
                </h2>
              </CCol>
            )}
            <CCardBody>
              <Switch>
                {tabRoutes.map((route, index) => (
                  <Route key={index} path={route.path} exact={route.exact}>
                    <route.component
                      applicationId={applicationId}
                      pageAfterSave={pageAfterSave}
                      applicationConfig={applicationConfig.data}
                    />
                  </Route>
                ))}
                <Redirect
                  from="/application/applications/new"
                  to="/application/applications/new/quotes"
                />
              </Switch>
            </CCardBody>
          </CCard>
        </CCol>
        {(getDetailsLoading || applicationConfig.loading) && (
          <Loading text="Loading application..." />
        )}
        {isFormLoading && <Loading text="Saving application..." />}
      </CRow>
      {isApplicationAmended && (
        <ConfirmationModal
          modalType={MODAL_TYPE.WARNING}
          isShown={isApplicationAmended}
          toggler={() =>
            dispatch(setIsApplicationAmended(!isApplicationAmended))
          }
          bodyText="You have amended this application"
          onCancel={() =>
            dispatch(revertChanges(tabName as APPLICATION_STEPS, nextPath))
          }
          onConfirm={() =>
            dispatch(
              saveForm(
                tabName as APPLICATION_STEPS,
                applicationId as string,
                true,
                nextPath
              )
            )
          }
          cancelButtonText="Cancel amendment"
          confirmButtonText="Save and continue"
          testId="amendment"
        />
      )}
    </>
  );
};

export default ApplicationDetails;
