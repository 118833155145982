export const AUSTRALIAN_STATE_OPTIONS = [
  "NSW",
  "VIC",
  "QLD",
  "TAS",
  "ACT",
  "SA",
  "NT",
  "WA",
];
