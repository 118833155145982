import React, { useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import UAParser from "ua-parser-js";
import { DATATABLE_MAX_ROWS } from "../../../common/constants/datatable";
import { getOffset } from "../../../common/utils/dataTable";
import { processErrorMessage } from "../../../error-handler/utils";
import { setNotification } from "../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../notification/dispatchers";
import UserService from "../../services/UserService";
import { LoginHistory } from "../../types/UserState";
import "./LoginHistory.scss";

interface LoginHistoriesProps {
  userId: number;
}

const LoginHistories: React.FunctionComponent<LoginHistoriesProps> = ({
  userId,
}: LoginHistoriesProps) => {
  const notifDispatch = useNotificationDispatch();
  const [loginHistories, setLoginHistories] = useState<{
    data: LoginHistory[];
    totalRecords: number;
    isLoading: boolean;
  }>({
    data: [],
    totalRecords: 0,
    isLoading: false,
  });

  const getLoginHistories = useCallback(
    async (page = 1) => {
      try {
        const data = await UserService.getLoginHistories({
          userId,
          offset: page ? getOffset(page, DATATABLE_MAX_ROWS) : undefined,
          limit: DATATABLE_MAX_ROWS,
        });

        setLoginHistories({
          data: data.data,
          isLoading: false,
          totalRecords: data.count as number,
        });
      } catch (error) {
        const body = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            id: "client-admin-error",
            body,
            className: "qst-notif-danger",
          })
        );
      }
    },
    [userId, notifDispatch]
  );

  useEffect(() => {
    getLoginHistories(1);
  }, [getLoginHistories]);

  const testIdWrapper = (value: React.ReactNode, testId: string) => {
    return <span data-testid={testId}>{value}</span>;
  };

  const columns: IDataTableColumn<LoginHistory>[] = [
    {
      name: "Date",
      cell: ({ id, createdAt }: LoginHistory) =>
        testIdWrapper(createdAt, `date-${id}`),
    },
    {
      name: "IP",
      cell: ({ id, ipAddress }: LoginHistory) =>
        testIdWrapper(ipAddress, `ip-addr-${id}`),
    },
    {
      name: "Location",
      cell: ({ id, locationName }: LoginHistory) =>
        testIdWrapper(locationName, `ip-addr-${id}`),
    },
    {
      name: "User-Agent",
      cell: ({ id, userAgent }: LoginHistory) => {
        let ua = null;
        if (userAgent) {
          const uaParser = new UAParser(userAgent as string);
          const { browser } = uaParser.getResult();
          ua = `${browser.name} ${browser.major}`;
        }

        return testIdWrapper(ua, `ip-addr-${id}`);
      },
    },
  ];

  return (
    <div className="quest-datatable">
      <h3 className="f-quest-navy f-bold mb-4">Login Histories</h3>
      <DataTable
        className="login-history-list"
        noHeader
        responsive
        columns={columns}
        data={loginHistories.data}
        sortServer
        pagination
        paginationServer
        paginationTotalRows={loginHistories.totalRecords}
        paginationComponentOptions={{ noRowsPerPage: true }}
        progressPending={loginHistories.isLoading}
        paginationPerPage={DATATABLE_MAX_ROWS}
        onChangePage={(page) => {
          getLoginHistories(page);
        }}
      />
    </div>
  );
};

export default LoginHistories;
