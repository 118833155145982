import { Method } from "axios";
import * as qs from "query-string";
import { DATATABLE_MAX_ROWS } from "../../common/constants/datatable";
import APIService from "../../common/services/APIService";
import { ListResponse } from "../../common/types/ListResponse";
import { getOffset } from "../../common/utils/dataTable";
import { ChangePassword } from "../types/ChangePassword";
import { User, UserListParams } from "../types/user";
import { LoginHistoriesRequest, LoginHistory } from "../types/UserState";

class UserService {
  static async changePassword(
    data: ChangePassword
  ): Promise<string | undefined> {
    return APIService.jsonRequest<string, ChangePassword>(
      {
        method: "POST",
        path: "/iam/auth/change-password",
        data,
      },
      true
    );
  }

  public static async getLoginHistories(
    payload: LoginHistoriesRequest
  ): Promise<ListResponse<LoginHistory>> {
    const { userId, ...params } = payload;
    const queryParams = qs.stringify(params, { encode: false });
    const method: Method = "GET";
    const path = `/iam/users/${userId}/login-histories?${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<LoginHistory>,
      unknown
    >(
      {
        method,
        path,
      },
      true
    );

    return result;
  }

  public static async getUserList(
    params?: UserListParams
  ): Promise<ListResponse<User>> {
    const limit = params?.limit ?? DATATABLE_MAX_ROWS;
    const queryParams = qs.stringify({
      limit,
      offset: getOffset(params?.page ?? 1, limit),
      clientId: params?.clientId,
      keywords: params?.keyword,
    });

    const result = await APIService.jsonRequest<ListResponse<User>, unknown>(
      {
        method: "GET",
        path: "/iam/users?" + queryParams,
      },
      true
    );
    return result;
  }

  public static async listManagedUsers(
    params?: UserListParams
  ): Promise<ListResponse<User>> {
    const limit = params?.limit ?? DATATABLE_MAX_ROWS;
    const queryParams = qs.stringify({
      limit,
      offset: getOffset(params?.page ?? 1, limit),
      clientIds: params?.clientId,
      keywords: params?.keyword,
    });

    const result = await APIService.jsonRequest<ListResponse<User>, unknown>(
      {
        method: "GET",
        path: "/iam/managed-users?" + queryParams,
      },
      true
    );
    return result;
  }
}

export default UserService;
