import { ListResponse } from "../../../common/types/ListResponse";
import {
  InternalNotesListParams,
  InternalNote,
} from "../../types/InternalNotes";
import {
  GetInternalNotesList,
  GetInternalNotesListSuccess,
  GetInternalNotesListError,
  GET_APPLICATION_INTERNAL_NOTES_LIST,
  GET_APPLICATION_INTERNAL_NOTES_LIST_SUCCESS,
  GET_APPLICATION_INTERNAL_NOTES_LIST_ERROR,
} from "../types/internalNotes";

export const getInternalNotesList = (
  applicationId: string,
  params?: InternalNotesListParams
): GetInternalNotesList => ({
  type: GET_APPLICATION_INTERNAL_NOTES_LIST,
  applicationId,
  params,
});

export const getInternalNotesListSuccess = (
  internalNotesResponse: ListResponse<InternalNote>
): GetInternalNotesListSuccess => ({
  type: GET_APPLICATION_INTERNAL_NOTES_LIST_SUCCESS,
  internalNotes: internalNotesResponse.data,
  count: internalNotesResponse.count ?? 0,
});

export const getInternalNotesListError = (): GetInternalNotesListError => ({
  type: GET_APPLICATION_INTERNAL_NOTES_LIST_ERROR,
});
