import { useCallback, useState } from "react";
import { AxiosError } from "axios";
import _get from "lodash/get";
import { useSelector } from "react-redux";
import { ERROR_CODES } from "../../error-handler";
import { processErrorMessage } from "../../error-handler/utils";
import { useDownloadDocument } from "../../files";
import { setNotification } from "../../notification/actions/creators";
import { useNotificationDispatch } from "../../notification/dispatchers";
import { getExtras } from "../actions/creators/extras";
import {
  APPLICANT_EQUIFAX_ENQUIRY_KEY,
  APPLICANT_ENQUIRY_DOCUMENT,
} from "../constants/extra";
import { useExtraDispatch } from "../dispatchers";
import { extrasDataSelector } from "../selectors/extras";
import ApplicationService from "../services/ApplicationService";
import { EquifaxApplyReportResponse } from "../types/CreditReference";
import { DocumentExtras, ScoreExtras } from "../types/Extras";
import { Guarantor } from "../types/Guarantor";

export const useApplicantEquifax = (
  applicationId: string,
  applicantEntityId: number | null
): {
  equifaxData: ScoreExtras | undefined;
  document: DocumentExtras | undefined;
  loadEquifaxRequest: () => Promise<void>;
  isLoadingEquifax: boolean;
  downloadDocument: () => void;
} => {
  const notifDispatch = useNotificationDispatch();
  const dispatch = useExtraDispatch();
  const { downloadFile } = useDownloadDocument();
  const extrasData = useSelector(extrasDataSelector);
  const equifaxData = _get(
    extrasData,
    APPLICANT_EQUIFAX_ENQUIRY_KEY
  ) as ScoreExtras;
  const document = _get(
    extrasData,
    APPLICANT_ENQUIRY_DOCUMENT
  ) as DocumentExtras;
  const [isLoadingEquifax, setIsLoadingEquifax] = useState(false);

  const loadEquifaxRequest = useCallback(async () => {
    if (isLoadingEquifax) {
      return;
    }

    setIsLoadingEquifax(true);

    try {
      const response = (await ApplicationService.getEquifaxScoredEnquiryReport(
        applicationId,
        [applicantEntityId as number]
      )) as EquifaxApplyReportResponse;

      const errorData = response.data.errors;
      if (errorData.length > 0) {
        const error = errorData[0];
        error.errors.forEach((err) => {
          notifDispatch(
            setNotification({
              body: err,
              className: "qst-notif-danger",
            })
          );
        });
      } else {
        dispatch(
          getExtras(applicationId, {
            [APPLICANT_EQUIFAX_ENQUIRY_KEY]: ["score"],
            [APPLICANT_ENQUIRY_DOCUMENT]: ["id"],
          })
        );

        notifDispatch(
          setNotification({
            body: `Successfully retrieved applicant score/documents`,
            className: "qst-notif-success",
          })
        );
      }
    } catch (e) {
      const error = e as AxiosError;
      const errorMsg =
        error.response?.data?.errorCode === ERROR_CODES.TOO_MANY_REQUESTS
          ? "An ongoing request is still in progress. It will be completed short while."
          : processErrorMessage(error);

      notifDispatch(
        setNotification({
          body: errorMsg,
          className: "qst-notif-danger",
        })
      );
    } finally {
      setIsLoadingEquifax(false);
    }
  }, [
    applicationId,
    applicantEntityId,
    setIsLoadingEquifax,
    isLoadingEquifax,
    dispatch,
    notifDispatch,
  ]);

  const downloadDocument = useCallback(() => {
    downloadFile(
      `/application/applications/${applicationId}/documents/${document.id}/download`
    );
  }, [applicationId, downloadFile, document]);

  return {
    equifaxData,
    document,
    loadEquifaxRequest,
    isLoadingEquifax,
    downloadDocument,
  };
};

export const useGuarantorEquifax = (
  guarantor: Guarantor,
  applicationId: string,
  equifaxExtrasKey: string,
  documentExtrasKey: string
): {
  name: string;
  entityId: number | undefined;
  equifaxData: ScoreExtras | undefined;
  document: DocumentExtras | undefined;
  loadEquifaxRequest: () => Promise<void>;
  isLoadingEquifax: boolean;
  downloadDocument: () => void;
} => {
  const dispatch = useExtraDispatch();
  const notifDispatch = useNotificationDispatch();
  const { downloadFile } = useDownloadDocument();
  const extrasData = useSelector(extrasDataSelector);
  const equifaxData = _get(extrasData, equifaxExtrasKey) as ScoreExtras;
  const document = _get(extrasData, documentExtrasKey) as DocumentExtras;
  const [isLoadingEquifax, setIsLoadingEquifax] = useState(false);
  const guarantorName = [
    guarantor?.firstName,
    guarantor?.middleName,
    guarantor?.lastName,
  ]
    .filter((v) => Boolean(v))
    .join(" ");

  const loadEquifaxRequest = useCallback(async () => {
    if (isLoadingEquifax) {
      return;
    }

    setIsLoadingEquifax(true);

    try {
      const response = (await ApplicationService.getEquifaxApplyReport(
        applicationId,
        [guarantor.entityId as number]
      )) as EquifaxApplyReportResponse;

      const errorData = response.data.errors;
      if (errorData.length > 0) {
        const error = errorData[0];
        error.errors.forEach((err) => {
          notifDispatch(
            setNotification({
              body: err,
              className: "qst-notif-danger",
            })
          );
        });
      } else {
        dispatch(
          getExtras(applicationId, {
            [equifaxExtrasKey]: ["score"],
            [documentExtrasKey]: ["id"],
          })
        );
        notifDispatch(
          setNotification({
            body: `Successfully retrieved score/documents for guarantor "${guarantorName}"`,
            className: "qst-notif-success",
          })
        );
      }
    } catch (e) {
      const error = e as AxiosError;
      if (
        (error as AxiosError).response?.data?.errorCode ===
        ERROR_CODES.TOO_MANY_REQUESTS
      ) {
        notifDispatch(
          setNotification({
            body:
              "An ongoing request is still in progress. It will be completed short while.",
            className: "qst-notif-danger",
          })
        );
      } else {
        const errorMsg = processErrorMessage(error);
        notifDispatch(
          setNotification({
            body: errorMsg,
            className: "qst-notif-danger",
          })
        );
      }
    } finally {
      setIsLoadingEquifax(false);
    }
  }, [
    guarantorName,
    guarantor,
    applicationId,
    setIsLoadingEquifax,
    isLoadingEquifax,
    dispatch,
    notifDispatch,
    documentExtrasKey,
    equifaxExtrasKey,
  ]);

  const downloadDocument = useCallback(() => {
    downloadFile(
      `/application/applications/${applicationId}/documents/${document.id}/download`
    );
  }, [applicationId, downloadFile, document]);

  return {
    name: guarantorName,
    entityId: guarantor?.entityId,
    equifaxData,
    document,
    loadEquifaxRequest,
    isLoadingEquifax,
    downloadDocument,
  };
};
