export const BOOLEAN_YES = true;
export const BOOLEAN_NO = false;

export const BOOLEAN_YES_LABEL = "Yes";
export const BOOLEAN_NO_LABEL = "No";

export const BOOLEAN_OPTIONS = [
  {
    value: BOOLEAN_YES,
    label: BOOLEAN_YES_LABEL,
  },
  {
    value: BOOLEAN_NO,
    label: BOOLEAN_NO_LABEL,
  },
];
