import React from "react";
import { RouteComponentProps } from "react-router-dom";

import ApplicationList from "../components/ApplicationList";

const ApplicationListPage: React.FunctionComponent<RouteComponentProps> = () => (
  <ApplicationList
    showSearchSection={false}
    showNavigationSection={true}
    showCloneAction={true}
  />
);

export default ApplicationListPage;
