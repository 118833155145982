import React, { useCallback, useEffect, useState } from "react";
import { CCol, CRow, CInput, CButton } from "@coreui/react";
import { AxiosError } from "axios";
import PopoverInfo from "../../../common/components/PopoverInfo";
import { processErrorMessage } from "../../../error-handler/utils";
import { setNotification } from "../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../notification/dispatchers";
import ClientService from "../../services/ClientService";
import ClientDropDown, { TClient } from "../ClientDropdown";

export type FilterData = {
  keyword: string;
  client: TClient | null;
};
export type RefUserListFilterProps = {
  onFilterChange?: (values: FilterData) => void;
  onClearFilers?: () => void;
  filterData?: FilterData;
} & React.HTMLProps<HTMLDivElement>;

const defaultFilterData: FilterData = {
  client: null,
  keyword: "",
};

const RefUserListFilter: React.FC<RefUserListFilterProps> = (
  props: RefUserListFilterProps
) => {
  const filterData = {
    ...defaultFilterData,
    ...props.filterData,
  };
  const [keyword, setKeyword] = useState("");
  const notifDispatch = useNotificationDispatch();

  useEffect(() => {
    setKeyword(filterData.keyword);
  }, [filterData.keyword]);

  const onFilterChange = (dataProp: Partial<FilterData>) => {
    props.onFilterChange?.({
      ...filterData,
      ...dataProp,
    });
  };

  const onKeywordChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setKeyword(event.target.value);
  };

  const onKeywordSearch: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      onFilterChange({
        keyword,
      });
    }
  };

  const getClientOptions = useCallback(
    async (keyword): Promise<TClient[]> => {
      let options: TClient[] = [];
      try {
        const clients = await ClientService.listRefClients({
          keyword,
          includeDerivedAccess: true,
        });

        options = clients.data.map((client) => {
          return {
            label: client.name,
            value: client.id,
          };
        });
      } catch (error) {
        const message = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            body: message,
            className: "qst-notif-danger",
          })
        );
      }

      return options;
    },
    [notifDispatch]
  );

  return (
    <CRow className={props.className}>
      <CCol xs={12} md={6} lg={8} className="d-flex align-items-center">
        <div className="keyword">
          <CInput
            value={keyword}
            onChange={onKeywordChange}
            onKeyPress={onKeywordSearch}
            type="text"
            placeholder="Filter by keyword"
            autoComplete="off"
            className="mb-1"
            data-testid="keyword"
          />
          <button type="submit" data-testid="search-button" />
        </div>
        <PopoverInfo
          positions={["right"]}
          content={
            <>
              <div>
                &quot;Filter by keyword&quot; will work on the following fields:
              </div>
              <ul className="pl-4 mt-2">
                <li>Name</li>
                <li>Mobile</li>
                <li>Email</li>
              </ul>
            </>
          }
        />
      </CCol>
      <CCol xs={12} md={6} lg={4}>
        <ClientDropDown
          name="client"
          id="client"
          value={filterData?.client}
          loadOptions={getClientOptions}
          onChange={(client) => {
            onFilterChange({
              client,
            });
          }}
          placeholder="Filter by Client"
        />
      </CCol>
      <CCol xs={12} className="filters-row-2 my-3">
        <CButton
          className="quest-button primary clear-btn"
          onClick={props.onClearFilers}
          data-testid="clear-filters"
        >
          Clear Filters
        </CButton>
      </CCol>
    </CRow>
  );
};

export default RefUserListFilter;
