import util from "util";
import React, { ReactNode } from "react";
import { SagaMiddleware } from "@redux-saga/core";
import {
  configureStore,
  EnhancedStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { Action, CombinedState, combineReducers, Reducer } from "redux";
import createSagaMiddleware from "redux-saga";

export const reduxSagaSetup = <S, A extends Action = Action>(
  reducers: {
    [K in keyof S]: Reducer<S[K], A>;
  }
): {
  store: EnhancedStore<CombinedState<S>, A>;
  sagaMiddleware: SagaMiddleware<Record<string, string>>;
} => {
  const sagaMiddleware = createSagaMiddleware();
  const combinedReducers = combineReducers(reducers);
  const store = configureStore({
    reducer: combinedReducers,
    middleware: [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }),
      sagaMiddleware,
    ],
  });

  return { store, sagaMiddleware };
};

export const testIdWrapper = (value: ReactNode, testId: string): ReactNode => {
  return <span data-testid={testId}>{value}</span>;
};

export const formatMobile = (mobile = ""): string => {
  return util.format("%d%d %d%d%d%d %d%d%d%d", ...mobile.split(""));
};

export const promiseDelay = (delayInMs = 500): Promise<unknown> => {
  return new Promise((resolve) => {
    setTimeout(resolve, delayInMs);
  });
};
