import { useEffect, useState, useCallback } from "react";
import FeatureService from "../services/Service";
import { FeatureFlags } from "../types/Feature";

interface FeatureState {
  features: FeatureFlags;
  isLoading: boolean;
  error: Error | undefined;
}
export const useFeature = (featureKeys: string[]): FeatureState => {
  const [hookState, setHookState] = useState<FeatureState>({
    features: {},
    isLoading: false,
    error: undefined,
  });
  const getFeaturFlags = useCallback(async () => {
    setHookState((state) => ({
      ...state,
      isLoading: true,
    }));

    const newHookState: Partial<FeatureState> = {};
    try {
      const { data } = await FeatureService.getFeaturesByKeys(featureKeys);
      newHookState.features = data;
    } catch (e) {
      newHookState.features = {};
      newHookState.error = e as Error;
    }

    setHookState((state) => ({
      ...state,
      ...newHookState,
      isLoading: false,
    }));
  }, [setHookState, featureKeys]);

  useEffect(() => {
    getFeaturFlags();
  }, [getFeaturFlags]);

  return hookState;
};
