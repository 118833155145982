import React, { useCallback } from "react";
import { AxiosError } from "axios";
import { GroupBase } from "react-select";
import ReactSelectAsync, { AsyncProps } from "react-select/async";
import { processErrorMessage } from "../../error-handler/utils";
import { actionCreator, dispatch } from "../../notification";
import UserService from "../../user/services/UserService";
import { User } from "../../user/types/user";

export type TUser = { label: string; value: number };

interface CustomSelectAsyncProps {
  clientId?: number;
  customLabel?: (user: User, i?: number) => TUser;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UserDropdown = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  clientId,
  customLabel,
  ...props
}: AsyncProps<Option, IsMulti, Group> & CustomSelectAsyncProps) => {
  const { useNotificationDispatch } = dispatch;
  const { setNotification } = actionCreator;
  const notifDispatch = useNotificationDispatch();

  const userQuery = useCallback(
    async (keyword): Promise<Option[]> => {
      let options: Option[] = [];

      try {
        const users = await UserService.getUserList({ keyword, clientId });
        options = (users.data.map((user, i) => {
          return customLabel
            ? customLabel(user, i)
            : {
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
              };
        }) as unknown) as Option[];
      } catch (error) {
        const message = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            body: message,
            className: "qst-notif-danger",
          })
        );
      }

      return options;
    },
    [notifDispatch, setNotification, clientId, customLabel]
  );

  return (
    <ReactSelectAsync
      placeholder="Filter by user"
      isClearable={true}
      defaultOptions={true}
      loadOptions={userQuery}
      {...props}
    />
  );
};

export default UserDropdown;
