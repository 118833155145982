import React from "react";
import { PageRoute } from "../../common/types/PageRoute";

const RefClientList = React.lazy(() => import("../pages/ref-client/list"));
const RefClientAdmin = React.lazy(() => import("../pages/ref-client/admin"));
const RefUserList = React.lazy(() => import("../pages/ref-user/list"));
const RefUserAdmin = React.lazy(() => import("../pages/ref-user/admin"));

const routes: PageRoute[] = [
  {
    path: "/iam/ref-clients/:clientId/admin",
    name: "Ref Client Admin",
    component: RefClientAdmin,
    exact: true,
  },
  {
    path: "/iam/ref-clients",
    name: "Ref Client List",
    component: RefClientList,
    exact: true,
  },
  {
    path: "/iam/ref-users/:userId/admin",
    name: "Ref User Admin",
    component: RefUserAdmin,
    exact: true,
  },
  {
    path: "/iam/ref-users",
    name: "Ref User List",
    component: RefUserList,
    exact: true,
  },
];

export default routes;
