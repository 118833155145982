import "./GenerateDocumentsLoading.scss";
import React from "react";
import { SyncOutlined } from "@ant-design/icons";

const GenerateDocumentsLoading: React.FunctionComponent = () => {
  return (
    <div className="generate-documents-loading-overlay">
      <div
        className="generate-documents-loading"
        data-testid="generate-documents-loading"
      >
        Generating document(s)
        <SyncOutlined spin className="loading-icon" />
      </div>
    </div>
  );
};

export default GenerateDocumentsLoading;
