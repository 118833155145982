import { RefUserFormData, Status } from "../types/ref-user";

export const defaultRefUser: RefUserFormData = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  client: null,
  status: Status.ACTIVE,
};
