import joi from "joi";
import { lenders } from "../constants/applicationAdmin";

export const adminSchema = joi.object({
  status: joi.string().messages({
    "string.empty": "Status is required",
  }),
  lenderName: joi
    .string()
    .valid(...Object.values(lenders))
    .optional()
    .allow("", null),
  lenderRef: joi.string().max(255).optional().allow("", null),
});
