export enum NOTIFICATION_IDS {
  CONTRACT_GENERATION_ERRORS = "CONTRACT_GENERATION_ERRORS",
  NOT_FOUND_ERRORS = "NOT_FOUND_ERRORS",
  GET_DOCUMENTS_ERROR = "GET_DOCUMENTS_ERROR",
  DELETE_DOCUMENT_ERROR = "DELETE_DOCUMENT_ERROR",
  UPLOAD_DOCUMENT_ERROR = "UPLOAD_DOCUMENT_ERROR",
  SUBMIT_SETTLEMENT_ERROR = "SUBMIT_SETTLEMENT_ERROR",
  GET_APPROVAL_CONDITION_ERROR = "GET_APPROVAL_CONDITION_ERROR",
  QUOTE_CALCULATE_ERROR = "QUOTE_CALCULATE_ERROR",
}
