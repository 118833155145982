import React, { useState } from "react";
import { CButton, CSpinner } from "@coreui/react";
import { AxiosError } from "axios";
import { useSelector } from "react-redux";
import striptags from "striptags";
import RichTextEditor from "../../../../../common/components/RichTextEditor/RichTextEditor";
import { processErrorMessage } from "../../../../../error-handler/utils";
import { setNotification } from "../../../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../../../notification/dispatchers";
import { getIsApplicationStatusLockedSelector } from "../../../../selectors/applicationForm";
import ApplicationService from "../../../../services/ApplicationService";
import { InternalNote } from "../../../../types/InternalNotes";

type InternalNoteEditorProps = {
  applicationId: string;
  onSucessfulCreate?: (data: InternalNote) => void;
};

const InternalNoteEditor: React.FC<InternalNoteEditorProps> = ({
  applicationId,
  onSucessfulCreate,
}: InternalNoteEditorProps) => {
  const [notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isApplicationLocked = useSelector(getIsApplicationStatusLockedSelector);
  const notifDispatch = useNotificationDispatch();

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      const data = await ApplicationService.createInternalNotes(
        applicationId,
        notes.trim()
      );
      setNotes("");
      notifDispatch(
        setNotification({
          body: "Internal notes has been saved",
          className: "qst-notif-success",
        })
      );
      onSucessfulCreate?.(data.data);
    } catch (error) {
      const message = processErrorMessage(error as AxiosError);
      notifDispatch(
        setNotification({
          body: message,
          className: "qst-notif-danger",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mb-3" data-testid="internal-notes-editor">
      <RichTextEditor
        value={notes}
        readOnly={isApplicationLocked}
        onChange={(note) => {
          setNotes(note.trim());
        }}
      />
      <CButton
        className="quest-button primary mt-3"
        type="button"
        onClick={onSubmitHandler}
        data-testid="save-note"
        disabled={!striptags(notes) || isApplicationLocked}
      >
        {isLoading && (
          <CSpinner
            size="sm"
            className="my-1 mt-1"
            data-testid="note-spinner"
          />
        )}
        Save
      </CButton>
    </div>
  );
};

export default InternalNoteEditor;
