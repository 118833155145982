import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.scss";

import React from "react";
import cs from "classnames";
import ReactQuill, { ReactQuillProps } from "react-quill";
import striptags from "striptags";

const DEFAULT_FORMATS: string[] = [
  "header",
  "align",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
  "link",
];

const DEFAULT_MODULES = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "clean"],
  ],
};

const DEFAULT_ALLOWED_TAGS: string[] = [
  "h1",
  "h2",
  "h3",
  "p",
  "ul",
  "ol",
  "li",
  "br",
  "u",
  "s",
  "em",
  "a",
  "strong",
];

interface RichTextEditorProps extends ReactQuillProps {
  value?: string;
  maxLength?: number;
  allowedTags?: string[];
  className?: string;
  testId?: string;
  toolbar?: boolean;
  onChange: (value: string) => void;
}
const defaultQuillProps: ReactQuillProps = {
  formats: DEFAULT_FORMATS,
  modules: DEFAULT_MODULES,
};

const RichTextEditor: React.FC<RichTextEditorProps> = (
  props: RichTextEditorProps
) => {
  const {
    testId,
    onChange,
    className,
    allowedTags = DEFAULT_ALLOWED_TAGS,
    toolbar = true,
    ...restProps
  } = props;
  const quillProps: ReactQuillProps = {
    ...defaultQuillProps,
    ...restProps,
  };
  const onChangeHandler = (note: string) => {
    onChange(striptags(note, allowedTags));
  };

  return (
    <div
      className={cs(
        "qf-rich-text-editor",
        {
          "hide-toolbar": !toolbar,
        },
        className
      )}
      data-testid={testId}
    >
      <ReactQuill {...quillProps} onChange={onChangeHandler} />
    </div>
  );
};

export default RichTextEditor;
