import {
  InternalNotesActions,
  GET_APPLICATION_INTERNAL_NOTES_LIST,
  GET_APPLICATION_INTERNAL_NOTES_LIST_SUCCESS,
  GET_APPLICATION_INTERNAL_NOTES_LIST_ERROR,
} from "../actions/types/internalNotes";
import { InternalNotesState } from "../types/InternalNotes";

export const internalNotesState: InternalNotesState = {
  notesList: {
    data: [],
    count: 0,
    isLoading: false,
  },
};

export const reducer = (
  state: InternalNotesState = internalNotesState,
  action: InternalNotesActions
): InternalNotesState => {
  switch (action.type) {
    case GET_APPLICATION_INTERNAL_NOTES_LIST: {
      return {
        ...state,
        notesList: {
          ...state.notesList,
          isLoading: true,
        },
      };
    }
    case GET_APPLICATION_INTERNAL_NOTES_LIST_SUCCESS: {
      return {
        ...state,
        notesList: {
          ...state.notesList,
          data: action.internalNotes,
          isLoading: false,
          count: action.count,
        },
      };
    }
    case GET_APPLICATION_INTERNAL_NOTES_LIST_ERROR: {
      return {
        ...state,
        notesList: {
          ...state.notesList,
          isLoading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
