import React, { useEffect } from "react";
import { CCol, CRow, CLabel, CInput, CSelect, CForm } from "@coreui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import cs from "classnames";
import { SubmitHandler, useForm } from "react-hook-form";
import { QuestButton } from "../../../common/components/QuestButton";
import { FormMode } from "../../../common/types/Form";
import { STATUS, defaultRefClient } from "../../constants/client";
import { RefClientFormData } from "../../types/ref-client";
import { refClientSchema } from "../../validations/ref-client";

export type RefClientAdminFormProps = {
  data?: RefClientFormData;
  bdm?: string;
  mode?: FormMode;
  onSubmit?: (data: RefClientFormData) => Promise<void>;
  loading?: boolean;
};
const RefClientAdminForm: React.FC<RefClientAdminFormProps> = (
  props: RefClientAdminFormProps
) => {
  const { mode = FormMode.CREATE, bdm } = props;
  const { register, handleSubmit, errors: formErrors, reset } = useForm<
    RefClientFormData
  >({
    resolver: joiResolver(refClientSchema),
    defaultValues: defaultRefClient,
  });
  const onSubmit: SubmitHandler<RefClientFormData> = async (data) => {
    props.onSubmit?.(data);
  };

  useEffect(() => {
    reset(props.data);
  }, [reset, props.data]);

  return (
    <CForm
      className="row mb-3"
      data-testid="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <CCol xl={6}>
        <CRow>
          <CCol
            xl={9}
            xs={12}
            className={cs("mb-3", {
              "with-error": formErrors.name,
            })}
          >
            <CLabel className="required">Name</CLabel>
            <CInput
              name="name"
              innerRef={register()}
              data-testid="name"
              autoComplete="off"
            />
            {formErrors.name && (
              <span className="validation-error" data-testid="name-error">
                {formErrors.name.message}
              </span>
            )}
          </CCol>
        </CRow>
        {mode === FormMode.EDIT && (
          <CRow>
            <CCol xl={9} xs={12} className="mb-3">
              <CLabel>Broker BDM</CLabel>
              <CInput data-testid="bdm" value={bdm} readOnly={true} />
            </CCol>
          </CRow>
        )}
        <CRow>
          <CCol xl={9} xs={12}>
            <CLabel className="required">Status</CLabel>
            <CSelect name="status" innerRef={register()} data-testid="status">
              {Object.entries(STATUS).map(([key, label]) => (
                <option key={key} value={key}>
                  {label}
                </option>
              ))}
            </CSelect>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={12}>
            <QuestButton
              disabled={Boolean(props.loading)}
              data-testid="submit"
              className="mt-4"
            >
              {mode === FormMode.CREATE ? "Create" : "Update"}
            </QuestButton>
          </CCol>
        </CRow>
      </CCol>
    </CForm>
  );
};

export default RefClientAdminForm;
