import { Method } from "axios";
import _pick from "lodash/pick";
import * as queryString from "query-string";
import qs from "query-string";
import { DATATABLE_MAX_ROWS } from "../../common/constants/datatable";
import APIService from "../../common/services/APIService";
import { ListResponse } from "../../common/types/ListResponse";
import { SingleResponse } from "../../common/types/SingleResponse";
import { getOffset } from "../../common/utils/dataTable";
import { ApplicantRequest } from "../types/ApplicantRequest";
import { ApplicantResponse } from "../types/ApplicantResponse";
import { StatusLenderForm } from "../types/ApplicationAdmin";
import { ApplicationListRequest } from "../types/ApplicationListRequest";
import { ApplicationRequest } from "../types/ApplicationRequest";
import { ApplicationResponse } from "../types/ApplicationResponse";
import { ApprovalConditionsResponse } from "../types/ApprovalConditionsResponse";
import { Guarantor } from "../types/Guarantor";
import { GuarantorsRequest } from "../types/GuarantorsRequest";
import {
  InternalNote,
  InternalNotesListParams,
  InternalNotesResponse,
} from "../types/InternalNotes";
import { NoteRequest } from "../types/NoteRequest";
import { NoteResponse } from "../types/NoteResponse";
import { QuoteRequest } from "../types/QuoteRequest";
import { QuoteResponse } from "../types/QuoteResponse";
import { RequiredDocuments } from "../types/RequiredDocuments";
import { SecurityRequest } from "../types/SecurityRequest";
import { SecurityResponse } from "../types/SecurityResponse";
import { WithdrawRequest, StatusReason } from "../types/WithdrawRequest";

class ApplicationService {
  public static async getApplicationList(
    params: ApplicationListRequest
  ): Promise<ListResponse<ApplicationResponse>> {
    const limit = params?.limit ?? DATATABLE_MAX_ROWS;
    const queryParams = qs.stringify({
      ..._pick(params, ["status", "limit", "order"]),
      offset: getOffset(params?.page ?? 1, limit),
      query: params.search,
      clientId: params.client,
      userId: params.user,
    });

    const method: Method = "GET";
    const path = `/application/applications?${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<ApplicationResponse>,
      unknown
    >(
      {
        method,
        path,
      },
      true
    );
    return result;
  }

  public static async getApplicationDetails(
    applicationId: string
  ): Promise<SingleResponse<ApplicationResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicationResponse>,
      unknown
    >(
      {
        method: "GET",
        path: `/application/applications/${applicationId}`,
      },
      true
    );
    return result;
  }

  public static async saveApplication(
    data: ApplicationRequest
  ): Promise<SingleResponse<ApplicationResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicationResponse>,
      ApplicationRequest
    >(
      {
        method: "POST",
        path: `/application/applications`,
        data,
      },
      true
    );
    return result;
  }

  public static async saveQuote(
    data: QuoteRequest,
    applicationId: string
  ): Promise<SingleResponse<QuoteResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<QuoteResponse>,
      QuoteRequest
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/quotes`,
        data,
      },
      true
    );
    return result;
  }

  public static async saveApplicant(
    data: ApplicantRequest,
    applicationId: string
  ): Promise<SingleResponse<ApplicantResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicantResponse>,
      ApplicantRequest
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/applicants`,
        data,
      },
      true
    );
    return result;
  }

  public static async updateApplicant(
    data: Partial<ApplicantRequest>,
    applicationId: string
  ): Promise<SingleResponse<ApplicantResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicantResponse>,
      Partial<ApplicantRequest>
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/applicants/update`,
        data,
      },
      true
    );
    return result;
  }

  public static async saveGuarantors(
    data: GuarantorsRequest,
    applicationId: string
  ): Promise<ListResponse<Guarantor>> {
    const result = await APIService.jsonRequest<
      ListResponse<Guarantor>,
      GuarantorsRequest
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/guarantors`,
        data,
      },
      true
    );
    return result;
  }

  public static async saveSecurity(
    data: SecurityRequest,
    applicationId: string
  ): Promise<SingleResponse<SecurityResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<SecurityResponse>,
      SecurityRequest
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/securities`,
        data,
      },
      true
    );
    return result;
  }

  public static async updateSecurity(
    data: Partial<SecurityRequest>,
    applicationId: string
  ): Promise<SingleResponse<SecurityResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<SecurityResponse>,
      Partial<SecurityRequest>
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/securities/update`,
        data,
      },
      true
    );
    return result;
  }

  public static async saveNote(
    data: NoteRequest,
    applicationId: string
  ): Promise<SingleResponse<NoteResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<NoteResponse>,
      NoteRequest
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/notes`,
        data,
      },
      true
    );
    return result;
  }

  public static async submitApplication(
    applicationId: string
  ): Promise<SingleResponse<ApplicationResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicationResponse>,
      unknown
    >(
      {
        method: "PUT",
        path: `/application/applications/${applicationId}/submit`,
      },
      true
    );
    return result;
  }

  public static async submitSettlement(
    applicationId: string
  ): Promise<SingleResponse<ApplicationResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicationResponse>,
      unknown
    >(
      {
        method: "PUT",
        path: `/application/applications/${applicationId}/submit-settlement`,
      },
      true
    );
    return result;
  }

  public static async getRequiredDocuments(
    applicationId: string
  ): Promise<SingleResponse<RequiredDocuments>> {
    const result = await APIService.jsonRequest<
      SingleResponse<RequiredDocuments>,
      unknown
    >(
      {
        method: "GET",
        path: `/application/applications/${applicationId}/required-documents`,
      },
      true
    );

    return result;
  }

  public static async cloneApplication(
    applicationId: string
  ): Promise<SingleResponse<ApplicationResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicationResponse>,
      unknown
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/clone`,
      },
      true
    );
    return result;
  }

  public static async withdrawApplication(
    applicationId: string,
    data: WithdrawRequest
  ): Promise<SingleResponse<ApplicationResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicationResponse>,
      WithdrawRequest
    >(
      {
        method: "PUT",
        path: `/application/applications/${applicationId}/withdraw`,
        data,
      },
      true
    );
    return result;
  }

  public static async getApprovalConditions(
    applicationId: string
  ): Promise<SingleResponse<ApprovalConditionsResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApprovalConditionsResponse>,
      unknown
    >(
      {
        method: "GET",
        path: `/application/applications/${applicationId}/approval-conditions`,
      },
      true
    );
    return result;
  }

  public static async getStatusReasons(
    statusGroup: string[]
  ): Promise<ListResponse<StatusReason>> {
    const result = await APIService.jsonRequest<
      ListResponse<StatusReason>,
      unknown
    >(
      {
        method: "GET",
        path: `/assessment/assessments/status-reason?statusGroups=${statusGroup}`,
      },
      true
    );
    return result;
  }

  public static async moveApplication(
    applicationNumber: string,
    clientId: number,
    userId: number
  ): Promise<SingleResponse<ApplicationResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicationResponse>,
      unknown
    >(
      {
        method: "POST",
        path: "/application/applications/move",
        data: {
          userId,
          clientId,
          applicationNumber,
        },
      },
      true
    );
    return result;
  }

  public static async getByApplicationNumber(
    applicationNum: string
  ): Promise<SingleResponse<ApplicationResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicationResponse>,
      unknown
    >(
      {
        method: "GET",
        path: `/application/applications/get-by-application-number/${applicationNum}`,
      },
      true
    );
    return result;
  }

  public static async administrate(
    applicationId: string,
    formValues: StatusLenderForm
  ): Promise<SingleResponse<ApplicationResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicationResponse>,
      unknown
    >(
      {
        method: "PATCH",
        path: `/application/applications/${applicationId}/update`,
        data: formValues,
      },
      true
    );
    return result;
  }

  public static async createInternalNotes(
    applicationId: string,
    note: string
  ): Promise<SingleResponse<InternalNote>> {
    const result = await APIService.jsonRequest<
      SingleResponse<InternalNote>,
      unknown
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/internal-notes`,
        data: { note },
      },
      true
    );
    return result;
  }

  public static async getInternalNotesList(
    applicationId: string,
    payload?: InternalNotesListParams
  ): Promise<ListResponse<InternalNotesResponse>> {
    const queryParams = queryString.stringify({
      offset: payload?.offset,
      order: payload?.order,
      limit: payload?.limit ?? DATATABLE_MAX_ROWS,
    });
    const method: Method = "GET";
    const path = `/application/applications/${applicationId}/internal-notes?${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<InternalNotesResponse>,
      unknown
    >(
      {
        method,
        path,
      },
      true
    );

    return result;
  }

  public static async deleteInternalNote(
    applicationId: string,
    internalNoteId: number
  ): Promise<SingleResponse<InternalNote>> {
    const method: Method = "DELETE";
    const path = `/application/applications/${applicationId}/internal-notes/${internalNoteId}`;

    const result = await APIService.jsonRequest<
      SingleResponse<InternalNote>,
      unknown
    >(
      {
        method,
        path,
      },
      true
    );

    return result;
  }

  public static async getEquifaxScoredEnquiryReport(
    applicationId: string,
    entityIds: number[]
  ): Promise<SingleResponse<Record<string, unknown>>> {
    const result = await APIService.jsonRequest<
      SingleResponse<Record<string, unknown>>,
      unknown
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/credit-report/generate-equifax-company-scored-enquiry-report`,
        data: { entityIds },
      },
      true
    );
    return result;
  }

  public static async getEquifaxApplyReport(
    applicationId: string,
    entityIds: number[]
  ): Promise<SingleResponse<Record<string, unknown>>> {
    const result = await APIService.jsonRequest<
      SingleResponse<Record<string, unknown>>,
      { entityIds: number[] }
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/credit-report/generate-equifax-commercial-apply-report`,
        data: { entityIds },
      },
      true
    );
    return result;
  }
}

export default ApplicationService;
