import { useEffect } from "react";
import { useSelector } from "react-redux";
import { initUserData } from "../actions/creators/authentication";
import { useAuthenticationDispatch } from "../dispatchers";
import { getIsAuthenticatedSelector } from "../selectors/authentication";
import { getAccessToken } from "../utils";

const useUserDataPreloader = (): {
  initialising: boolean;
} => {
  const token = getAccessToken();
  const authDispatch = useAuthenticationDispatch();
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);

  useEffect(() => {
    if (token && !isAuthenticated) {
      authDispatch(initUserData());
    }
  }, [token, isAuthenticated, authDispatch]);

  return {
    initialising: Boolean(token && !isAuthenticated),
  };
};

export default useUserDataPreloader;
