import { useCallback, useEffect, useState } from "react";
import add from "date-fns/add";

export const useIdleAppHandler = (
  duration: Duration,
  onTimeExpire?: () => void
): void => {
  const [endTime, setEndTime] = useState<number>();

  const recordTime = useCallback(() => {
    setEndTime(add(new Date(), duration).getTime());
  }, [duration]);

  const timeCheck = useCallback(
    (e: CustomEvent) => {
      const timeExpired = endTime && endTime <= Date.now();

      if (timeExpired) {
        onTimeExpire?.();
      } else {
        setEndTime(undefined);
      }
    },
    [endTime, onTimeExpire]
  ) as EventListener;

  useEffect(() => {
    window.onblur = recordTime;
    window.onfocus = timeCheck;

    return () => {
      window.onblur = () => {
        return;
      };
      window.onfocus = () => {
        return;
      };
    };
  }, [timeCheck, recordTime]);

  useEffect(() => {
    if (!document.hasFocus() && !endTime) {
      recordTime();
    }
  }, [recordTime, endTime]);

  return;
};
