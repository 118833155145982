import { Dictionary } from "../../common/types/Dictionary";

export enum APPLICATION_PUBLIC_STATUSES {
  QUOTED = "QUOTED",
  DRAFTED = "DRAFTED",
  SUBMITTED = "SUBMITTED",
  IN_PROGRESS = "IN_PROGRESS",
  APPROVED = "APPROVED",
  IN_SETTLEMENT = "IN_SETTLEMENT",
  SETTLED = "SETTLED",
  DECLINED = "DECLINED",
  WITHDRAWN = "WITHDRAWN",
}
export enum APPLICATION_STATUSES {
  QUOTED = "QUOTED",
  DRAFTED_NEW = "DRAFTED_NEW",
  DRAFTED_AMENDED = "DRAFTED_AMENDED",
  SUBMITTED_NEW = "SUBMITTED_NEW",
  SUBMITTED_AMENDED = "SUBMITTED_AMENDED",
  IN_PROGRESS_CREDIT_WAITING_FOR_MORE_INFO = "IN_PROGRESS_CREDIT_WAITING_FOR_MORE_INFO",
  IN_PROGRESS_CREDIT_IN_PROGRESS = "IN_PROGRESS_CREDIT_IN_PROGRESS",
  IN_PROGRESS_CREDIT_REFERRED = "IN_PROGRESS_CREDIT_REFERRED",
  APPROVED_WAITING_FOR_MORE_INFO = "APPROVED_WAITING_FOR_MORE_INFO",
  IN_SETTLEMENT_WAITING_FOR_MORE_INFO = "IN_SETTLEMENT_WAITING_FOR_MORE_INFO",
  IN_SETTLEMENT_READY_FOR_SETTLEMENT = "IN_SETTLEMENT_READY_FOR_SETTLEMENT",
  IN_SETTLEMENT_SETTLEMENT_IN_PROGRESS = "IN_SETTLEMENT_SETTLEMENT_IN_PROGRESS",
  IN_SETTLEMENT_SETTLEMENT_REFERRED = "IN_SETTLEMENT_SETTLEMENT_REFERRED",
  IN_SETTLEMENT_WAITING_FOR_PAYMENT = "IN_SETTLEMENT_WAITING_FOR_PAYMENT",
  SETTLED = "SETTLED",
  WITHDRAWN_BY_INTRODUCER = "WITHDRAWN_BY_INTRODUCER",
  WITHDRAWN_BY_LENDER = "WITHDRAWN_BY_LENDER",
  DECLINED = "DECLINED",
}

type ApplicationStatusLabels = {
  [key: string]: string;
};
export const APPLICATION_PUBLIC_STATUS_LABELS: ApplicationStatusLabels = {
  [APPLICATION_PUBLIC_STATUSES.QUOTED]: "Quoted",
  [APPLICATION_PUBLIC_STATUSES.DRAFTED]: "Drafted",
  [APPLICATION_PUBLIC_STATUSES.SUBMITTED]: "Submitted",
  [APPLICATION_PUBLIC_STATUSES.IN_PROGRESS]: "In Progress",
  [APPLICATION_PUBLIC_STATUSES.APPROVED]: "Approved",
  [APPLICATION_PUBLIC_STATUSES.IN_SETTLEMENT]: "In Settlement",
  [APPLICATION_PUBLIC_STATUSES.SETTLED]: "Settled",
  [APPLICATION_PUBLIC_STATUSES.WITHDRAWN]: "Withdrawn",
  [APPLICATION_PUBLIC_STATUSES.DECLINED]: "Declined",
};

export const APPLICATION_STATUS_GROUP: Dictionary = {
  TO_BE_SUBMITTED: [
    APPLICATION_STATUSES.QUOTED,
    APPLICATION_STATUSES.DRAFTED_NEW,
    APPLICATION_STATUSES.DRAFTED_AMENDED,
  ],
  SUBMITTED: [
    APPLICATION_STATUSES.SUBMITTED_NEW,
    APPLICATION_STATUSES.SUBMITTED_AMENDED,
  ],
  IN_PROGRESS: [
    APPLICATION_STATUSES.IN_PROGRESS_CREDIT_WAITING_FOR_MORE_INFO,
    APPLICATION_STATUSES.IN_PROGRESS_CREDIT_IN_PROGRESS,
    APPLICATION_STATUSES.IN_PROGRESS_CREDIT_REFERRED,
  ],
  APPROVED: [
    APPLICATION_STATUSES.APPROVED_WAITING_FOR_MORE_INFO,
    APPLICATION_STATUSES.IN_SETTLEMENT_WAITING_FOR_MORE_INFO,
  ],
  IN_SETTLEMENT: [
    APPLICATION_STATUSES.IN_SETTLEMENT_READY_FOR_SETTLEMENT,
    APPLICATION_STATUSES.IN_SETTLEMENT_SETTLEMENT_IN_PROGRESS,
    APPLICATION_STATUSES.IN_SETTLEMENT_SETTLEMENT_REFERRED,
    APPLICATION_STATUSES.IN_SETTLEMENT_WAITING_FOR_PAYMENT,
  ],
  LOCKED: [
    APPLICATION_STATUSES.IN_SETTLEMENT_WAITING_FOR_PAYMENT,
    APPLICATION_STATUSES.SETTLED,
    APPLICATION_STATUSES.WITHDRAWN_BY_INTRODUCER,
    APPLICATION_STATUSES.WITHDRAWN_BY_LENDER,
    APPLICATION_STATUSES.DECLINED,
  ],
};

export const APPLICATION_STATUS_COLORS: Dictionary = {
  success: [
    APPLICATION_PUBLIC_STATUSES.APPROVED,
    APPLICATION_PUBLIC_STATUSES.SETTLED,
  ],
  warning: [
    APPLICATION_PUBLIC_STATUSES.QUOTED,
    APPLICATION_PUBLIC_STATUSES.DRAFTED,
    APPLICATION_PUBLIC_STATUSES.SUBMITTED,
    APPLICATION_PUBLIC_STATUSES.IN_PROGRESS,
    APPLICATION_PUBLIC_STATUSES.IN_SETTLEMENT,
  ],
  danger: [],
};

export const APPLICATION_STATUS_LABELS: ApplicationStatusLabels = {
  [APPLICATION_STATUSES.QUOTED]: "Quoted",
  [APPLICATION_STATUSES.DRAFTED_NEW]: "Drafted: New",
  [APPLICATION_STATUSES.DRAFTED_AMENDED]: "Drafted: Amended",
  [APPLICATION_STATUSES.SUBMITTED_NEW]: "Submitted: New",
  [APPLICATION_STATUSES.SUBMITTED_AMENDED]: "Submitted: Amended",
  [APPLICATION_STATUSES.IN_PROGRESS_CREDIT_WAITING_FOR_MORE_INFO]:
    "In Progress: Credit waiting for more info",
  [APPLICATION_STATUSES.IN_PROGRESS_CREDIT_IN_PROGRESS]:
    "In Progress: Credit in progress",
  [APPLICATION_STATUSES.IN_PROGRESS_CREDIT_REFERRED]:
    "In Progress: Credit Referred",
  [APPLICATION_STATUSES.APPROVED_WAITING_FOR_MORE_INFO]:
    "Approved: Waiting for more info",
  [APPLICATION_STATUSES.IN_SETTLEMENT_WAITING_FOR_MORE_INFO]:
    "In Settlement: Waiting for more info",
  [APPLICATION_STATUSES.IN_SETTLEMENT_READY_FOR_SETTLEMENT]:
    "In Settlement: Ready for settlement",
  [APPLICATION_STATUSES.IN_SETTLEMENT_SETTLEMENT_IN_PROGRESS]:
    "In Settlement: In Progress",
  [APPLICATION_STATUSES.IN_SETTLEMENT_SETTLEMENT_REFERRED]:
    "In Settlement: Settlement Referred",
  [APPLICATION_STATUSES.IN_SETTLEMENT_WAITING_FOR_PAYMENT]:
    "In Settlement: Waiting for payment",
  [APPLICATION_STATUSES.SETTLED]: "Settled",
  [APPLICATION_STATUSES.WITHDRAWN_BY_INTRODUCER]: "Withdrawn by Introducer",
  [APPLICATION_STATUSES.WITHDRAWN_BY_LENDER]: "Withdrawn by Lender",
  [APPLICATION_STATUSES.DECLINED]: "Declined",
};

export const APPLICATION_STATUS_IN_PROGRESS_LABELS: ApplicationStatusLabels = {
  IN_PROGRESS_CREDIT_WAITING_FOR_MORE_INFO: "Credit waiting for more info",
  IN_PROGRESS_CREDIT_IN_PROGRESS: "Credit in progress",
  IN_PROGRESS_CREDIT_REFERRED: "Credit referred",
};

export const APPLICATION_STATUS_IN_SETTLEMENT_LABELS: ApplicationStatusLabels = {
  IN_SETTLEMENT_WAITING_FOR_MORE_INFO: "Waiting for more info",
  IN_SETTLEMENT_READY_FOR_SETTLEMENT: "Ready for settlement",
  IN_SETTLEMENT_SETTLEMENT_IN_PROGRESS: "Settlement in progress",
  IN_SETTLEMENT_SETTLEMENT_REFERRED: "Settlement referred",
  IN_SETTLEMENT_WAITING_FOR_PAYMENT: "Waiting for payment",
};

export const AMENDMENT_TRIGGER_STATUSES = [
  APPLICATION_STATUSES.SUBMITTED_AMENDED,
  APPLICATION_STATUSES.SUBMITTED_NEW,
  APPLICATION_STATUSES.IN_PROGRESS_CREDIT_WAITING_FOR_MORE_INFO,
  APPLICATION_STATUSES.IN_PROGRESS_CREDIT_IN_PROGRESS,
  APPLICATION_STATUSES.IN_PROGRESS_CREDIT_REFERRED,
  APPLICATION_STATUSES.APPROVED_WAITING_FOR_MORE_INFO,
  APPLICATION_STATUSES.IN_SETTLEMENT_WAITING_FOR_MORE_INFO,
  APPLICATION_STATUSES.IN_SETTLEMENT_READY_FOR_SETTLEMENT,
  APPLICATION_STATUSES.IN_SETTLEMENT_SETTLEMENT_IN_PROGRESS,
  APPLICATION_STATUSES.IN_SETTLEMENT_SETTLEMENT_REFERRED,
  APPLICATION_STATUSES.IN_SETTLEMENT_WAITING_FOR_PAYMENT,
];
